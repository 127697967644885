@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.actions) {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    word-break: keep-all;
  }
}
