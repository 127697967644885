@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow-x: initial;
  flex-grow: 1;
}
