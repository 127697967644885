@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.infoContainer) {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }

  margin-bottom: 10px;
}

:local(.info) {
  flex-grow: 1;
  margin: auto 0;
}

:local(.image) {
  margin-right: 10px;
}

:local(.actionCreateContainer) {
  margin-bottom: 10px;
}
