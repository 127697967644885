@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.leftPane) {
  display: flex;
  flex-shrink: 1 !important;
}

:local(.rightPane) {
  flex: 1;
  display: flex;
  border-left: 1px solid $colorArctic;
}

:local(.splitView) {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  flex-direction: row;
  align-items: stretch;
}
