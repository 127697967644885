@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  flex: 1 1 auto;
  display: flex;
  padding: 7px;
  flex-direction: column;

  &::-webkit-scrollbar-thumb {
    background: $colorSilverSurfer;
  }
  &::-webkit-scrollbar-track {
    background: $colorArctic;
  }
}
