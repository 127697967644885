:local(.fancy-border) {
  color: #2b3039 !important;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #5AC361, #2089C9) border-box !important;
  border: 2px solid transparent !important;
  font-size: .92307692rem !important;
  border-radius: 5px !important;
  padding: 6.5px 1.538em 6.5px !important;
}

:local(.fancy-border:hover) {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #3AA141, #2379AE) border-box !important;
}

@media (max-width: 768px) {
  :local(.fancy-border) {
    margin-top: 0.6rem !important;
  }
}