@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.left) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $gap-2;
  > :global(.Select-Search-Container) {
    flex: 1 1 0%;
  }
}
