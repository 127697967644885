@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;

  img {
    height: auto;
    width: 400px;
  }

  p {
    font-size: 16px;
  }
}
