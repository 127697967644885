@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.primary) {
  background-color: $colorPrimary!important;
  color: $colorBrightWhite!important;
}

:local(.centered) {
  text-align: center;
}

:local(.noMargin) {
  margin: 0 0 !important;
}
