@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.container) {
  width: 100%;
  background-color: white;
  z-index: 101;
  flex-shrink: 0;
  min-height: 50px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

:local(.context) {
  margin-left: 5px;
  margin-right: 5px;
  color: $colorLabstepBlue;
  font-weight: 500;
  font-family: 'poppins';
  font-size: 16px;
}

:local(.topBar) {
  display: flex;

  padding: 0px 8px 0px 16px;

  > * {
    flex: 1;
  }

  justify-content: space-between;
  height: $topbarHeight;
  margin: auto;
  align-items: center;
}

:local(.left) {
  overflow: hidden;
  display: flex;
  align-items: center;
  flex: 1;
  i {
    margin-bottom: 5px !important;
    margin-left: 10px;
  }
}

:local(.rightOptions) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0;
  button {
    white-space: nowrap;
    margin: 0px !important;
  }

  > div:not(#right-menu-right) {
    margin: 0px 5px;
  }
  > *:last-child {
    display: flex;
    justify-content: center;
    img {
      max-width: none;
    }
  }
}

:local(.bar) {
  cursor: pointer;
}

:local(.leftChevron) {
  margin-right: -3px !important;
}

:local(.sidebarIcon) {
  text-align: left !important;
}
:local(.right) {
  flex: initial;
}

:local(.simple) {
  background-color: white !important;
  > * {
    flex: 0 0 auto;
  }
}
