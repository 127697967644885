@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  height: 100%;
  display: flex !important;
  overflow: hidden;
  flex-direction: column;
}

:local(.topContainer) {
  padding-top: 55px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  color: white;
}

:local(.notificationsContainer) {
  color: inherit;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

:local(.signout) {
  margin-top: 15px;
  text-align: center;
}

:local(.imageContainer) {
  position: relative;
}

:local(.editButton) {
  background-color: white !important;
}

:local(.editButtonContainer) {
  position: absolute;
  border-radius: 100%;
  border: 2px solid $colorBlue;
  top: calc(50% + 20px);
  left: calc(50% + 20px);
}

:local(.headerContainer) {
  height: 50px;
  margin-top: 20px;
  text-align: center;
}
