@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.insertBelow) {
  position: absolute;
  right: 100%;
  visibility: hidden;
}

:local(.fieldName) {
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  &:hover {
    > div {
      visibility: visible;
    }
  }
}

:local(.toggleHiddenVisible) {
  visibility: hidden;

  @include mobile {
    visibility: visible;
  }
}

:local(.borderless) {
  composes: borderless from '../../../core/Table/Simple/Collapsible/styles.module.scss';
}