@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.white) {
  color: $colorBrightWhite;
  opacity: 1 !important;
}

:local(.group){
  margin: 0 2px !important
}

:local(.clickable) {
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
}
