@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.toggleContainer) {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  &:last-child {
    flex-grow: 1;
  }
}

:local(.listContainer) {
  margin-left: 10px;
  &:first-child {
    margin-left: 0px;
  }
}

:local(.actionContainer) {
  flex-grow: 1;
}
