@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.button) {
  margin-right: 5px;
  cursor: pointer;
  font-weight: 600;
}

:local(.active) i {
  color: $colorLabstepBlue !important;
}

:local(.colorBar) {
  margin-top: 2px;
  width: 20px;
  height: 4px;
  position: absolute;
  bottom: 1px;
}
