@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.toggleContainer) {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding: 5px;

  &:last-child {
    flex-grow: 1;
  }

}

:local(.toggleContainer):global(.active) {
  @include desktop {
    &:hover {
      :local(.actionContainer) {
        opacity: 1;
      }
    }

    :local(.actionContainer) {
      transition-property: opacity;
      transition-duration: 0.5s;
      opacity: 0;
      &:focus-within {
        opacity: 1;
      }
    }
  }
}




:local(.listContainer) {
  margin-left: 10px;
  &:first-child {
    margin-left: 0px;
  }
}

:local(.actionContainer) {
  flex-grow: 1;
}
