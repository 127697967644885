@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.text) {
  margin-right: 5px;
}

:local(.inverted) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
}

:local(.header) {
  font-weight: 500;
}
