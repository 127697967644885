@import 'labstep-web/scss/libs/_vars.scss';

:local(.create) {
  padding: 5px 0px;
  z-index: 4;
}

:local(.container) {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  > div:first-child {
    flex-grow: 1;
  }
}
