@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  flex-direction: column;
  gap: $gap-4;

  > div {
    display: flex;
    flex-direction: column;
    gap: $gap-2;
  }
}

:local(.close) {
  position: absolute;
  right: 0;
  top: 0;
  margin: $p-3 !important;
  cursor: pointer;
}
