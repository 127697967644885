@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  > div {
    margin-bottom: 5px;
  }
}

:local(.selected) {
  box-shadow: 0 0 0 2px $colorLabstepBlue;
}
