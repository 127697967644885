@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container){
  margin-left: 20px;
  flex-grow: 1;
}
:local(.liveExperiment) {
  display: flex;
  justify-content: flex-start;
  margin: 10px;
}

:local(.protocolName) {
  font-weight: 500;
  font-size: 15px;
}

:local(.protocolDate) {
  font-weight: 500;
  font-size: 11px;
  color: $colorEarlGray;
}

:local(.headerContainer) {
  display: flex;
  a {
    margin-left: 10px;
  }
}