:local(.compoundSpinner) {
  position: relative;
  height: 64px;
  width: 64px;
}

:local(.compoundSpinner) > div {
  position: absolute;
  top: 0;
  left: 0;
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
}

:local(.compoundSpinner) > div > img {
  width: 40px !important;
}
