@import 'src/labstep-web/scss/libs/_vars';

/* Media Queries */
@mixin mobile {
  @media screen and (max-width: $mobileWidth) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $mobileWidthMin) and (max-width: $tabletWidth) {
    @content;
  }
}

@mixin not-mobile {
  @media screen and (min-width: $mobileWidth) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $tabletWidthMin) {
    @content;
  }
}
