@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.row) {
  @include desktop {
    height: 100%;
  }
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  align-items: center;
  > div:first-child {
    flex-grow: 1;
  }
  > div:nth-child(2) {
    width: 30px;
    flex-shrink: 0;
  }
}
