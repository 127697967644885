@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.card) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  height: 485px;
  background: $colorArctic;
  border-radius: 5px;
  cursor: pointer;
  border: 4px solid transparent;
  padding: 0;
}

:local(.card):hover:not(:local(.selected)) {
  background: $colorAthensGray;
  border: 4px solid $colorAthensGray;
}

:local(.selected) {
  border: 4px solid $colorBlueGreen;
}

:local(.header) {
  padding: 16px 20px;
  width: 100%;
}

:local(.proHeader) {
  background: $premiumGradient !important;
  padding: 16px 20px;
  width: 100%;
  color: $colorArctic;
  border-radius: 5px 5px 0 0;
}

:local(.logo) {
  height: 48px;
  margin-bottom: 16px;
}

:local(.features) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $gap-1_5;
  flex-grow: 1;
  padding: 0px 20px;
}

:local(.pricing) {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  gap: $gap-2_5;
  padding: 16px 20px;
}

:local(.price) {
  font-weight: 600;
  font-size: 28px;
  text-align: right;
}

:local(.priceBeforeDiscount) {
  font-weight: 600;
  font-size: 15px;
  text-decoration-line: line-through;
  color: $colorEarlGray;
}

:local(.billingPeriod) {
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  text-align: right;
}

:local(.textSecondary) {
  color: $colorEarlGray;
}

:local(.icon) {
  color: $colorEarlGray;
}

:local(.checkIcon) {
  color: $colorGreen;
}

:local(.green) {
  color: $colorGreen;
}

:local(.blue) {
  color: $colorBlueGreen;
}

:local(.groupHeading) {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 28px;
  padding: 0 20px;

  p {
    text-transform: uppercase;
    min-width: max-content;
    margin: 0;
  }

  hr {
    width: 100%;
    border: 1px solid $colorAthensGray;
  }
}
