:local(.container) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:local(.info) {
  display: flex;
  > div:first-child {
    margin: 0;
    flex-shrink: 0;
    flex-grow: 0;
  }
  flex-grow: 1;
  margin-right: 10px;
}

:local(.name) {
  display: flex;
  align-items: center;
  span {
    word-break: break;
    margin: 0 10px;
  }
}
