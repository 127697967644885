:local(.expand) {
  margin-left: 10px;
}

:local(.linkButton) {
  width: 3em;
}

:local(.headerText) {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding-right: 3em;

  > span {
    display: flex;
    align-items: baseline;
  }
}

:local(.action) {
  width: 3em;
}

:local(.headerWithAction) {
  padding-right: 0;
}
