@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.topbar) {
  width: 100%;
  background-color: $colorPrimary;
  flex-shrink: 0;
  min-height: 50px;
}

:local(.parentContainer) {
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

:global {
  :local(.mainContent) {
    display: flex;
    flex: 1;
    overflow: hidden;

    .bm-menu {
      * {
        &:focus {
          outline: none;
        }
      }
      overflow: hidden !important;
    }
    .bm-item-list {
      overflow: hidden;
      > *:last-child {
        overflow: auto;
        flex: 1 1 auto;
      }
    }
  }
}

:local(.hidden) {
  margin-left: -230px;
}

:local(.left) {
  flex-shrink: 0;
  width: 65px;
  display: flex;
  flex-direction: column;
  > *:last-child {
    flex-grow: 1;
  }
  z-index: 100;
  position: sticky;
  top: 0px;
}

:local(.leftMain) {
  z-index: 1;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

:local(.right) {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: $colorArctic;
  overflow-y: hidden;
}

:local(.topbar) {
  color: inherit;
}

:local(.card) {
  padding: 20px;
  height: 200px;
  margin-bottom: 20px;
}
