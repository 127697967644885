@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.imageResizeContainer){
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  &:hover .vSlider {
    opacity: 1;
  }

  &:hover .hSlider {
    opacity: 1;
  }

  img {
    height: auto;
    width: 100%;
    max-height: none !important;
  }
}

:local(.heightRestrict){
  img {
    max-height: 360px !important;
  }
}

:local(.imageResizeHandle){
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 20%;
  min-width: 50px;
  transform: translateX(-50%);
  height: 8px;
  background-color: $colorRiverBed;
  cursor: row-resize;
  border-radius: 5px;
  opacity: 0;
  transition: 'opacity 0.3s ease-in-out',
}

:local(.hSlider){
  opacity: 0;
  appearance: none;
  background: transparent;
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: -10px;
  transform: translateX(50%);
  width: 100%;

  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default appearance */
    appearance: none;
    width: 6px;
    height: 50px;
    pointer-events: auto;
    background: $colorRiverBed;
    transform: translateY(-50%);
    border-radius: 5px;
    cursor: col-resize;
  }
}

:local(.vSlider){
  opacity: 0;
  appearance: none;
  background: transparent;
  writing-mode: vertical-lr;
  pointer-events: none;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateY(50%);
  height: 100%;

  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default appearance */
    appearance: none;
    width: 50px;
    pointer-events: auto;
    height: 6px;
    background: $colorRiverBed;
    transform: translateX(-50%);
    border-radius: 5px;
    cursor: row-resize;
  }
}