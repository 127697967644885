@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.card) {
  background-color: white;
  border: 1px solid $colorArctic;
  box-shadow: 0px 0px 10px #e7ebf0;
  border-radius: 5px;
  padding: 20px 20px 0 50px;
  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.state {
  margin-bottom: 10px;
  > div:first-child {
    flex-grow: 1;
  }
  display: flex;
}

:local(.top) {
  background-color: inherit;
}

:local(.topTop) {
  > div:first-child {
    flex-grow: 1;
  }
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: end;
  align-items: start;
  margin-bottom: 5px;
}
:local(.mainAction) {
  margin-right: 10px;
  @include mobile {
    margin-right: 5px;
  }
}
:local(.midAction) {
  margin-right: 10px;
}

:local(.topRight) {
  display: flex;
  align-items: center;
}

:local(.center) {
  padding: 10px 0px;
}

:global {
  :local(.bottom) {
    padding: 10px 0px;

    &.noBottomMargin {
      padding: 0px;
    }
  }
}
