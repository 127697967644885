:global {
  :local(.fieldName) {
    display: flex;
    position: relative;
    padding-right: 10px;
    align-items: center;

    > *:first-child {
      .ui.form .field > .selection.dropdown {
        min-width: 200px;
      }
      margin-right: 5px;
    }

    &:hover {
      > div {
        visibility: visible;
      }
    }
  }
}