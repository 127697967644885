:local(.pictograms) {
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 5px;
}

:local(.inline) {
  margin-bottom: 0px;
  margin-right: 5px;
  display: flex;
  img {
    align-self: center;
  }
}

:local(.container){
  display: flex;
}
