@import 'src/labstep-web/scss/libs/_vars.scss';

.ag-theme-alpine {
  .ag-root-wrapper {
    // show popups over grid borders
    overflow: visible;
  }

  .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
    opacity: 0;
  }

  .ag-cell,
  .ag-header-cell,
  .ag-header-group-cell {
    border-right: 1px solid $colorSilverSurfer !important;
  }

  .ag-side-buttons {
    // side bar open/close controlled externally
    display: none !important;
  }

  .ag-tool-panel-wrapper .ag-react-container {
    width: 100%;
    padding: $p-3;
  }

  .ag-cell.ag-cell-range-selected {
    border-right-color: #2196f3 !important;
  }

  .ag-popup-editor {
    border: none !important;
  }
  .ag-header-row {
    font-weight: 500 !important;
  }

  .ag-header-row:first-child .ag-header-group-cell-with-group {
    background-color: #fff;
  }

  .ag-header-cell-resize {
    opacity: 0;
  }

  .ag-center-cols-clipper {
    min-height: 0px !important;
  }

  .ag-status-bar {
    padding: 10px !important;
  }

  .ag-tool-panel-wrapper {
    background-color: $colorBrightWhite !important;
    width: 350px !important;
  }

  .ag-overlay {
    background: transparent;

    .ag-overlay-wrapper {
      background: transparent;
      pointer-events: none; // allow clicks to pass through
    }

    .ag-react-container {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 20px;
    }
  }

  .ag-checkbox .ag-input-wrapper {
    box-shadow: 0 0 0px 1px $colorSilverSurfer;
  }

  .ag-checkbox-input-wrapper:not(.ag-checked)::after {
    content: '' !important;
  }


  // hide the cell wrapper when editing inline
  // this is so the cell value is not shown when editing
  .ag-cell-inline-editing {
    .ag-cell-wrapper {
      display: none;
    }
  }

  .ag-center-cols-viewport {
    border-bottom: 1px solid $colorSilverSurfer;
  }

  .no-border {
    border: none !important;
  }

  --ag-header-background-color: #eff2f7;
  --ag-border-color: #dbe0e8;
  --ag-row-border-color: #dbe0e8;
  --ag-odd-row-background-color: #fff;

  .parent-header {
    ::after {
      top: 85% !important;
    }
  }

  .child-header {
    border-top: none !important;
    ::after {
      top: 0% !important;
      height: calc(100% - 85%) !important;
    }
  }

  font-family: inherit !important;
}
