:local(.primaryInfo) {
  display: flex;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

:local(.card) {
  flex-grow: 1;
}
