@import 'src/labstep-web/scss/libs/_vars.scss';

/**
 * Adding bottom padding to action div to counter semantic-ui
 * change of bottom padding to 0 on mobile
 */
:local(.actions) {
  @media only screen and (max-width: 767px) {
    padding-bottom: 1rem;
  }
}

:local(.warningHeader) {
  color: $colorBlushing!important;
}
