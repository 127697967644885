@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.unstyled) {
  color: inherit;
  opacity: 1 !important;
}

:local(.unstyled:hover) {
  color: inherit;
}

:local(.underline) {
  color: $colorRiverBed;
  text-decoration: underline;
}

:local(.underline:hover) {
  text-decoration: underline;
}

:local(.disabled) {
  pointer-events: none;
}