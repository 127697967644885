/**
 * Global variables for the Labstep theme
 *
 * These correspond to the variables in the Semantic UI theme
 */

/* Colors */

// White
$colorBrightWhite: #ffffff;
$colorBrightWhite50: rgba(255, 255, 255, 0.5);

// Blue
$colorLabstepBlue: #005bcc;

// Greys
$colorLightGrey: #fafafa;
$colorMidGrey: #cbd0d6;
$colorArctic: #eff2f7;
$colorAthensGray: #e8ebf0;
$colorSilverSurfer: #dbe0e8;
$colorEarlGray: #828e9e;
$colorRiverBed: #404e5b;
$colorLightBlack: #2d2d2d;

// Greens
$colorCutGrass: #69d570;
$colorWimbledon: #55c15c;
$colorPosionIvy: #1a5915;

// Reds
$colorBlushing: #fd5151;
$colorPunchDrunk: #dc3b3b;
$colorSangriaJug: #6c0c0c;

// Other
$colorYellow: #ffc214;
$colorPurple: #d38cff;
$colorBlueGreen: #2089c9;
$colorOrange: #ff8514;
$colorGreen: #5ac361;
$colorBlue: #0b3e8c;
$colorRed: #dd0000;
$colorTransparentRed: #FD51511A;
$colorCrimsonRed: #C10000;
$colorSailBoat: #b3cffc;
$colorViolet: #9b0ff1;
$premiumGradient: linear-gradient(102deg, #9747FF 0%, #80CAFF 100%);

// Specifics
$colorPrimary: $colorLabstepBlue;
$colorDefaultHeader: $colorRiverBed;
$colorDefaultText: $colorRiverBed;
$colorHyperlink: $colorLabstepBlue;

/** Sizes */

$unit: 4px;

// Padding
$p-0_5: calc($unit * 0.5);
$p-1: calc($unit * 1);
$p-1_5: calc($unit * 1.5);
$p-2: calc($unit * 2);
$p-2_5: calc($unit * 2.5);
$p-3: calc($unit * 3);
$p-3_5: calc($unit * 3.5);
$p-4: calc($unit * 4);
$p-5: calc($unit * 5);
$p-6: calc($unit * 6);
$p-7: calc($unit * 7);
$p-8: calc($unit * 8);
$p-9: calc($unit * 9);
$p-10: calc($unit * 10);

// Margin
$m-0_5: calc($unit * 0.5);
$m-1: calc($unit * 1);
$m-1_5: calc($unit * 1.5);
$m-2: calc($unit * 2);
$m-2_5: calc($unit * 2.5);
$m-3: calc($unit * 3);
$m-3_5: calc($unit * 3.5);
$m-4: calc($unit * 4);
$m-5: calc($unit * 5);
$m-6: calc($unit * 6);
$m-7: calc($unit * 7);

// Gap
$gap-0_5: calc($unit * 0.5);
$gap-1: calc($unit * 1);
$gap-1_5: calc($unit * 1.5);
$gap-2: calc($unit * 2);
$gap-2_5: calc($unit * 2.5);
$gap-3: calc($unit * 3);
$gap-3_5: calc($unit * 3.5);
$gap-4: calc($unit * 4);
$gap-5: calc($unit * 5);
$gap-6: calc($unit * 6);
$gap-7: calc($unit * 7);
$gap-8: calc($unit * 8);

/** Border Radius */

$defaultBorderRadius: 4px;

/** Device Widths */

$mobileWidthPxNumber: 768px;
$tabletWidthPxNumber: 1200px;
$mobileWidth: '768px';
$tabletWidth: '1200px';
$mobileWidthMin: '769px';
$tabletWidthMin: '1201px';

/** Container heights */

// Header
$menuHeight: 90px;

// Footer
$footerHeight: 220px;

// Topbar
$topbarHeight: 50px;
