@import 'src/labstep-web/scss/libs/_vars.scss';


:local(.link) {
  color: inherit;
  text-decoration: inherit;
}

:local(.span){
  color: $colorLabstepBlue;
  text-overflow: ellipsis;
  overflow: hidden;
}