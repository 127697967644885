:local(.container) {
  display: flex;
  flex: 1;
  > div:first-child {
    flex-grow: 1;
  }
  > div:last-child {
    min-width: 250px;
    margin-left: 20px;
  }
}
