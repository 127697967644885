@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.ui.modal.fullscreen {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.ui.modal.fullscreen .content {
  flex-grow: 1;
  overflow: scroll;
  /* padding-top: 2rem !important; may be needed for conditions table*/
  display: flex;
}

// Temporary Fix to use SUI 2.3
.ui.dimmer.modals {
  display: flex !important;
}
.ui.modal {
  margin-top: 0 !important;
  @include mobile {
    max-width: 600px;
  }
}
