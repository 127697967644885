@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.numberCircle) {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 7px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $gap-2_5;
  border-radius: 40px;
  border: 2px solid $colorSilverSurfer;
}

:local(.primary) {
  border: 2px solid $colorLabstepBlue;
  background: $colorLabstepBlue;
  color: var(--white, #fff);
}

:local(.complete) {
  border: 2px solid $colorGreen;
  background: $colorGreen;
  color: $colorBrightWhite;
  i {
    height: initial;
    width: initial;
    margin: initial;
  }
}

:local(.small) {
  border: none;
  background: $colorArctic;
  font-size: 12px;
  line-height: normal;
  height: 20px;
  width: 20px;
}
