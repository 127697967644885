@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.outerContainer) {
  background-color: $colorArctic;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  @include mobile {
    width: 100%;
  }
}

:local(.innerContainer) {
  background-color: white;
  flex-grow: 1;
  padding: 20px;
  margin: 5px;
  overflow: auto;
  border-radius: 5px;
}

:local(.children) {
  max-width: 900px;
  margin: auto;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 20px;
  padding-right: 20px;
}
