:local(.userBody) {
  display: flex;
  margin-top: 20px;
}

:local(.userProfileForm) {
  flex-grow: 1;
  padding-left: 1rem;
}

:local(.profilePhoto) {
  cursor: pointer;
  position: relative;
  height: min-content;
}

:local(.username){
  padding: 5px;
}