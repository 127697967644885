@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.list) {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 2px;
    background-color: $colorArctic;
    top: 0;
    bottom: 0;
    left: 27px;
    margin-left: -3px;
  }
}

:local(.item) {
  margin-bottom: 10px;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  padding: 0px 10px;
}

:local(.changes) {
  flex-grow: 1;
  margin-left: 15px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:local(.active) {
  background-color: $colorArctic;
};

:local(.date) {
  composes: headerSubTitle from '../../../core/Card/Generic/styles.module.scss'
}
