@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.logoContainer) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: white;
  > div:last-child {
    margin-top: 20px;
  }
}

:local(.fullPageLoader) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

:local(.fullPageLoader) > div {
  margin: 10px;
}

.message {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}