:local(.innerContainer) {
  composes: border from '../../File/Viewer/styles.module.scss';
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
  }
}

:local(.container) {
  position: relative;
}

:local(.expandIcon) {
  z-index: 1;
  position: absolute;
  top: 5px;
  left: 5px;
}