@import 'src/labstep-web/scss/libs/_vars.scss';


:global {
  :local(.container) {
    flex-grow:1;
    margin-right: $m-5;

    > span {
      flex-shrink: 0;
    }

    display: flex;
    align-items: center;
  }
}
