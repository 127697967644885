@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.preview) {
  background-color: $colorArctic;
  border-radius: 6px;
  box-shadow: 0px 0px 4px #00000040;
  display: flex;
  padding: $p-0_5 $p-1_5;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
  &:hover{
    :local(.label){
      opacity: 1;
      width: 44px;
    }
  }
  >*{
    margin-top: -$m-1_5 !important;
  }
}

:local(.label){
  opacity: 0;
  width: 0px;
  transition: 0.5s ease-in-out;
  font-weight: 600;
}