:local(.floatingSearchContainer) {
  position: relative;
  display: inline-block;
  >button{
    margin-right: 0px !important;
  }
}

:local(.dropdownContainer) {
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 1000;
  width: 270px;
  background-color: #fff;

  &.alignRight {
    left: auto;
    right: 0;
  }
}