@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.name) {
  flex-grow: 1;
  margin-right: 20px;
  white-space: nowrap;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  > i {
    margin-right: $m-2 !important;
  }
}

:local(.icon) {
  flex-shrink: 0;
}

:local(.item) {
  cursor: pointer;
  align-items: center;
}
