@import 'src/labstep-web/scss/libs/_vars.scss';


.groupHeading {
    padding: 4px 12px;
    text-transform: uppercase;
    color: $colorEarlGray;
    display: flex;
    align-items: center;
    gap: 6px;

    hr {
    width: 100%;
    border: 1px solid $colorArctic;
    }
  }

.select {
    width: 300px;
}