@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: -5px;

  > * {
    flex-grow: 1;
    margin-top: 5px !important;
    margin-left: 0px !important;
  }

  > *:last-child {
    margin-right: auto !important;
  }

  @include mobile {
    flex-direction: column-reverse;

    > * {
      margin-right: 0px !important;
    }
  }
}
