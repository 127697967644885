@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.open) {
  color: $colorArctic;
}

:local(.pending) {
  color: $colorYellow;
}

:local(.completed) {
  color: $colorGreen;
}
