@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:global {
  :local(.sidebarStyles) {
    z-index: 900;
    .sidebar {
      background-color: white;

      border: none!important;

      width: 500px!important;

      @include mobile {
        max-width: $mobileWidthPxNumber;
      }
    }
  }
}