:local(.container) {
  position: relative;
  outline: none;
  flex-grow: 1;
}

:local(.dragAndDropGraphic) {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: black;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  > div {
    font-size: 20px;
  }
}
