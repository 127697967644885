:local(.help) {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

:local(.content) {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
