@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  > * {
    margin-left: 2px;
  }
  > *:last-child {
    margin-right: 2px;
  }
}

:local(.circular) {
  border-radius: 100% !important;
}

:local(.count) {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $colorArctic;
  color: $colorRiverBed;
  border-radius: 20%;
}

:local(.pointer) {
  cursor: pointer;
}
