@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.metadata) {
  > small > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // AG Grid sets the row height based on the line height
  // meaning all text gets spaced out
  line-height: initial;
  margin-top: -$m-2;
  padding-bottom: $p-2;
}
