@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.segment) {
  display: flex;
  flex-direction: column;
  gap: $gap-2;
}

:local(.name) {
  align-items: center !important;
}
