:local(.progress) {
  flex-grow: 1;

  >div {
    margin: 0 !important;
  }
}

:local(.rowContainer) {
  >span {
    width: 200px;
  }
}
