@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;

  >div:nth-child(1) {
    width: 30px;
    margin-right:12px;
    display: flex;
    justify-content: center;
    i {
      margin: 0px !important;
    }
  }
}