@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.tagName) {
  font-style: normal;
  margin: 0px 5px;
}

:local(.tagItem) {
  margin-right: 5px!important;
  margin-bottom: 5px!important;
  background-color: $colorArctic;
}

:local(.removeAction) {
  margin-left: 9px;
}
