@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  composes: container from '../../../ExperimentWorkflow/Show/Center/styles.module.scss';
}

:local(.actions) {
  margin-bottom: 5px;
}

.banner {
  display: flex;
  justify-content: space-between;
}
