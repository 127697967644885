@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.arrowDown) {
  margin: -10px !important;
}

:local(.sortable) {
  cursor: pointer;
}

:local(.arrowContainer) {
  margin: 0 10px;
  cursor: pointer;
}

:local(.trigger) {
  font-size: 11px;

  &:hover {
    color: $colorLabstepBlue;
  }
}

:local(.order) {
  margin: 0 10px;
}
