@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.headerContainer) {
  min-height: $topbarHeight;
  padding-top: 10px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

:local(.header) {
  overflow: hidden;
  max-width: 70%;
}

:local(.main) {
  display: flex!important;
  flex-direction: column;
  height: 100%;
}

:local(.children) {
  overflow: auto;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
}

:local(.default) {
  padding: 10px;
}

:local(.footer) {
  z-index: 100;
  display: flex;
  justify-content: center;
  padding: 10px;
}
