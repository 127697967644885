@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.info) {
  display: flex;
  border: 1px solid $colorArctic;
  background-color: white;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px 5px 0 0;
}

:local(.name) {
  font-weight: 500;
  cursor: pointer;
}

:local(.infoContainer) {
  flex-grow: 1;
  display: flex;
}
:local(.actionContainer) {
  i {
    cursor: pointer;
    margin-left: 10px;
  }
}

:local(.contentContainer) {
  padding: 20px;
  border: 1px solid $colorArctic;
}

:local(.header) {
  display: flex;
  align-items: center;
  > *:first-child {
    margin-right: 10px;
  }
}
