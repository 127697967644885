:local(.title) {
  display: flex;
  align-items: center;

  > *:first-child {
    flex-grow: 1;
  }
}

:local(.container) {
  width: 100%;
}
