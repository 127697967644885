:local(.container) {
  cursor: pointer;
}

:local(.alertContainer) {
  display: flex;
  justify-content: space-between;
}

:local(.nameContainer) {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}