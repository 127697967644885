:global {
  :local(.mobile) {
    .ui .menu {
      bottom: 0!important;
      position: fixed!important;
      width: 100vw;
    }
    .ui.dropdown {
      display: flex;
      flex-direction: column;
    }
  }
}

:local(.backdrop) {
  background-color: black;
  opacity: 0.2;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
}

:local(.hide) {
  display: none;
}