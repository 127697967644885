:local(.imageContainer) {
  width: 100%;
}

:local(.imageDimensions) {
  width: auto;
  max-height: 360px;
  margin: auto;
  /* Make sure it doesn't spill over (metadata table) */
  max-width: 100% !important;
}

:local(.image) {
  composes: imageDimensions;
  cursor: pointer;
}

#lightboxBackdrop figure {
  background-color: white;
}

:local(.placeholder) {
  height: 100px;
  width: 100px;
}
