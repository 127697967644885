@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.action) {
  margin-top: 24px;
  align-self: flex-end;
}

:local(.viewAllContainer) {
  display: flex;
  justify-content: space-between;

}

:local(.card) {
  display: flex;
  flex-direction: column;

  table {
    margin-bottom: auto !important
  }
}