:local(.buttonGroup){
    width: 100%;
    display: flex;
    max-height: 200px;
    gap: 10px;
}

:local(.bigRadioButton){
    display: flex !important;
    padding: 12px 20px !important;
    gap: 8px;
    align-items: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    border-radius: 5px !important;
    border: var(--slash_menu_level, 1px) solid var(--Gray-Arctic, #EFF2F7) !important;
    background: var(--Gray-White, #FFF) !important;
}

:local(.bigRadioButton) i{
    margin-right: 0 !important;
    color: #404E5B !important;
}

:local(.active) {
    border: var(--slash_menu_level, 1px) solid var(--Gray-Arctic, #EFF2F7) !important;
    background: var(--Gray-LightGray, #FAFAFA) !important;
    box-shadow: 0px 2px 4px 0px rgba(130, 142, 158, 0.25) inset !important;
    color: var(--Primary-Labstep, #005BCC) !important;
}

:local(.metadataButton){
    border-radius: 6px 0 0 6px;
}

:local(.protocolButton){
    border-radius: 0 6px 6px 0;
}

