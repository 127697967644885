@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.clickable) {
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: baseline;
  &:hover {
    background-color: $colorArctic;
  }
  > i:last-child {
    margin-left: 10px;
  }
}
