:local(.container) {
  display: flex;
  align-items: baseline;

  > div:first-child {
    display: flex;
    align-items: baseline;
    flex-grow: 1;
  }

  > div:nth-child(2) {
    flex-shrink: 1;
  }
}
