@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.top) {
  > *:first-child {
    max-height: 40vh;
    overflow: auto;
  }
  padding: 10px;
}

:local(.footer) {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
  overflow: auto;
}

:local(.commentListContainer) {
  flex-grow: 1;
  overflow: auto;
  padding: 10px;
}

:local(.commentCreateContainer) {
  flex-shrink: 0;
  padding: 10px;
}
