@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.pagination) {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > *:not(:last-child) {
    padding-right: 15px;
  }

  @include mobile {
    align-items: center;
    overflow-x: hidden;
    flex-direction: column;
  }
}

:local(.showing) {
  height: min-content;
}
