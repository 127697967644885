@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.container) {
  display: flex;
  @include mobile {
    flex-direction: column;
  }

  > div:first-child {
    border: 1px solid $colorArctic;
    border-radius: 3px;
    padding: 10px !important;
    width: 200px;
    flex-shrink: 0;

    @include mobile {
      padding: 0px !important;
      border: none;
      width: 100%;
    }
  }

  > div:last-child {
    flex-grow: 1;
    padding-left: 20px;

    @include mobile {
      padding-left: 0px;
      padding-top: 20px;
    }
  }
}

:local(.item) {
  cursor: pointer;

  &:hover {
    color: $colorHyperlink;
  }
}

:local(.active) {
  color: $colorLabstepBlue;
}
