:local(.actionContainer) {
  > *:last-child {
    margin-top: 10px;
  }
}

:local(.header) {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}
