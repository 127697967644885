@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.count) {
  margin-left: 5px;
  display: inline-block;
  padding: 2px 7px;
  line-height: 1;
  border-radius: 6px;
  background-color: $colorArctic;
  color: $colorLightBlack !important;
  font-size: 12px;
  font-weight: 500;
}

:local(.inverted) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
}

:local(.header) {
  font-weight: 500;
}
