@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  flex-direction: column;
  width: 290px;
  box-shadow: 0px 0px 10px #e7ebf0;
  border-radius: 5px 0px 0px 5px;
  background-color: white;
}

:local(.panes) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  > div {
    width: 300px;
    padding: 20px 30px;
    margin-bottom: 2px;
  }

  > div:last-child {
    flex-grow: 1;
  }

  > div:not(:first-child) {
    border-top: 1px solid $colorArctic;
  }

  overflow-y: auto;
  overflow-x: clip;
}

:local(.linksContainer) {
  a {
    border-radius: 5px;
  }
}
