@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.card) {
  padding: 0px;
  border: 1px solid $colorArctic;
  border-bottom: 0px;
  &:last-child {
    border-bottom: 1px solid $colorArctic;
  }
}