@import 'src/labstep-web/scss/libs/_vars.scss';

.container {
    display: inline-flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: 1px solid $colorArctic;
    border-radius: 4px;
    margin-left: 10px;

    img {
        height: auto !important;
    }
}

.shiny {
    color: $premiumGradient !important;
}