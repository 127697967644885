@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';


:local(.container) {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: $defaultBorderRadius;
  padding: $p-5;
  background: #FFF;
  gap: 20px;
}


:local(.item) {
  flex: 1 0;
  display: flex;
  max-width: 100%;
  min-width: 400px;
  > div {
    width: 100%;
  }
  @include mobile {
    min-width: 300px;
  }
}
