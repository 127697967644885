:local(.item) {
  display: flex;
  z-index: 1000;
  > *:first-child {
    margin-right: 10px;
  }
  > *:last-child {
    flex-grow: 1;
  }
}
