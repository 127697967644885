@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.name) {
  font-weight: 500;
  font-family: 'poppins';
  font-size: 16px;
  min-width: 0; /* or some value */
  padding: 10px;
  text-overflow: ellipsis;
}
