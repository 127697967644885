@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  flex-direction: column;
}

:local(.completeAction) {
  display: flex;
  margin-bottom: 20px;
}

:local(.completeOption) {
  padding-right: 10px;
}

:local(.listContainer) {
  max-width: $tabletWidth;
}

:local(.actionButton) {
  width:100%;
}
