@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.name) {
  margin-top: 10px;
}

:local(.image) {
  width: 300px;
  height: auto;
}

:local(.logo) {
  height: 30px;
  width: auto;
}

:local(.quote) {
  composes: quote from '../Signup/Left/styles.module.scss';
}

:local(.leftContainer) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

:local(.infoMessage) {
  composes: infoMessage from '../Signup/Left/styles.module.scss';
}

:local(.info) {
  padding: 20px;
  width: 100%;
  background-color: $colorLabstepBlue;
  display: flex;
  align-items: center;
  > * {
    flex: 1;
    flex-shrink: 0;
  }
  img {
    max-width: 250px !important;
    margin: auto;
  }
}

:local(.container) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  > div:nth-child(2) {
    margin: 50px 0px;
  }
}
