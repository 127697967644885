@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.imageContainer) {
  composes: imageContainer from '../Signin/styles.module.scss';
}

.explainer {
  background-color: $colorArctic
}

.left-title {
  color: $colorBlue;
}

:local(.info) {
  border-radius: 4px !important;
}