:local(.footerCell) {
  flex: 1;

  > div {
    width: 250px;
    float: right;

    >div {
      >*:first-child {
        flex-shrink: 0;
        margin-right: 30px;
        text-align: left;
      }
      >*:last-child {
        flex-grow: 1;
      }
      display: flex;
    }
  }
}
:local(.outboundFooterOutCell) {
  > div {
    >div {
      >*:first-child {
        margin-right: 30px;
        text-align: left;
        flex-shrink: 0;
      }
      >*:last-child {
        flex-grow: 1;
      }
      display: flex;
    }
  }
}
