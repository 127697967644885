@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.new) {
  color: $colorYellow;
}

:local(.approved) {
  color: $colorPurple;
}

:local(.ordered) {
  color: $colorOrange;
}

:local(.back_ordered) {
  color: $colorBlue;
}

:local(.received) {
  color: $colorGreen;
}

:local(.cancelled) {
  color: $colorRed;
}
