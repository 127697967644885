@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:local(.topbar) {
  display: flex;
  align-items: center;
  padding: 20px;
  
  @include desktop {
    padding: 20px 50px;
  }
}

:local(.grid) {
  flex: 1 1 auto;
  display: flex;
  text-align: center;
}

:local(.column) {
  display: flex;
  flex: 1;
  flex-shrink: 0;
}

:local(.leftColumn) {
  flex-direction: column;
  height: 100vh;
  background-color: $colorArctic;
  >div:last-child {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  @include mobile {
    display: none;
  }
  @include tablet {
    display: none;
  }
}

:local(.inverted) {
  background-color: $colorLabstepBlue;
}

:local(.fixed) {
  position: fixed;
}

:local(.right) {
  text-align: left;
}

:local(.graphicsColumn) {
  height: 100%;
}

:local(.contentColumn) {
  justify-content: center;
  align-items: center;
  img {
    margin: auto;
    margin-bottom: 40px;
  }
  @include mobile {
    padding: 10px;
  }

  > div {
    flex: 1;
    max-width: 400px;
  }
}

:local(.header) {
  p {
    font-weight: 500;
    margin-bottom: 10px!important;
  }
}

:local(.centerAlign) {
  justify-content: center;
}