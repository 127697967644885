@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.body) {
  td {
    overflow: visible !important;
  }
}

:local(.header) {
  font-size: 11px;
}

:local(.sectionRow) {
  display: flex;
  justify-content: space-between;
}

:local(.borderless) {
  border: none !important;

  td {
    border: none !important;
  }

}