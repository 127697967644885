@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  align-items: center;
  > * {
    margin-right: 5px;
  }
}

:local(.input) {
  flex-grow: 1;
}

:local(.shareMessage) {
  margin-top: 10px;
}
