:local(.sharedBody) {
  margin-top: 10px !important;
  margin-bottom: 20px;
}

:local(.sharedFooter) {
  > div {
    margin-bottom: 25px;
  }
}

:local(.benefits) {
  padding: 50px 0px;

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
  }
}
