:local(.container) {
  display: flex;
  align-items: center;
  > *:first-child {
    padding-right: 10px;
  }
}

:local(.selectContainer) {
  min-width: 50px;
}
