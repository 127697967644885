@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.mobile) {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 101;
  top: 0px;
  overflow: hidden;
}

:local(.desktopRight) {
  min-width: 375px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  border-radius: 5px;
  border: 1px solid $colorArctic;
}

:local(.container) {
  display: flex;
  background-color: white;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0px 0px 10px #e7ebf0;
  border-radius: 0px 5px 5px 0px;
}

:local(.header) {
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid $colorArctic;
  min-height: 60px;
}

:local(.body) {
  flex-grow: 1;
  overflow: auto;
  padding: 20px;
  min-width: 375px;
  display: flex;
  flex-direction: column;

  > * {
    flex-grow: 1;
    flex-direction: column;
  }
}
