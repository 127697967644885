@import 'src/labstep-web/scss/libs/_vars.scss';

.container {
    padding-left: 5px;
    padding-right: 5px;
    background: $colorBrightWhite;
}
.prompt {
    color: $colorEarlGray;
}

.cell-input {
  width: 100%;
  padding: 5px;
  border: none;
  background: transparent;

  &:focus {
    outline-width: 0;
  }
}