@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  p,
  h1,
  h2,
  h3,
  h4 {
    word-break: break-word;
  }

  overflow: hidden;
}

:local(.center) {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 400px;
}
