:local(.container) {
  position: relative;
  overflow-x: auto;

  > div:first-child {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 3;
  }
}
