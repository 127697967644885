:global {
  :local(.container) {
    flex-wrap: wrap;
    display: flex;
    > .show-edit .toggle {
      flex-shrink: 1 !important;
    }
    align-items: baseline;

    > div:nth-child(2) {
      margin-left: 3px;
    }
    > div:last-child {
      display: flex;
      align-items: center;
      margin-left: 3px;
    }
  }
}