@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  border-right: 2px solid $colorRiverBed;
  &:hover {
    border-right-color: $colorLabstepBlue;
    i {
      color: $colorLabstepBlue;
    }
  }
  button {
    padding-top: 0 !important;
  }
  min-height: 20px;
}
