@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.step) {
  display: flex;
  padding: $p-1_5;
  align-items: center;
  gap: $gap-3;
  font-size: 13px;
  font-weight: initial;
  white-space: nowrap;
}
