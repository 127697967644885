/**
 * Labstep
 */

@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  position: absolute;
  z-index: 1000;
}

:local(.commentComponentActionContainer) {
  display: flex;
  justify-content: flex-end;
}

:local(.commentComponentContainer) {
  padding: 5px !important;
}
