@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.link) {
  cursor: pointer;
  &:hover {
    * {
      color: $colorLabstepBlue;
    }
  }
}
