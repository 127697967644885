@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  >div {
    height: 50px;
    &:first-child {
      height: 200px;
    }
    margin-bottom: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $colorSilverSurfer;
    &:hover {
      border-color: $colorLabstepBlue;
      cursor: pointer;
    }
  }
}

:local(.dropzone) {
  text-align: center;
}