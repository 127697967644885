@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.toggleContainer) {
  white-space: nowrap;
  &:hover {
    filter: saturate(1.2);
  }
}

:local(.status) {
  padding: 2px 8px 2px 8px;
  border-radius: 6px;
  color: white;
  i {
    margin: 0 !important;
  }
}

:local(.new) {
  composes: status;
  background-color: $colorYellow;
}

:local(.approved) {
  composes: status;
  background-color: $colorPurple;
}

:local(.ordered) {
  composes: status;
  background-color: $colorOrange;
}

:local(.back_ordered) {
  composes: status;
  background-color: $colorBlue;
}

:local(.partially_received) {
  composes: status;
  background-color: #9b0ff1;
}

:local(.received) {
  composes: status;
  background-color: $colorGreen;
}

:local(.cancelled) {
  composes: status;
  background-color: $colorRed;
}

:local(.container) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  > *:first-child {
    width: fit-content;
    margin-bottom: 5px;
  }

  > div:not(:first-child) {
    padding-left: 5px;
  }
}

:local(.row) {
  flex-direction: row !important;
  > *:first-child {
    width: fit-content;
    margin-bottom: 0px;
  }
}
