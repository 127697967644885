@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:global {
  :local(.container) {
    display: flex;
    align-items: center;

    @include desktop {
      &:hover {
        .to-appear {
          opacity: 1;
        }
      }

      .to-appear {
        transition-property: opacity;
        transition-duration: 0.5s;
        opacity: 0;
        &:focus-within {
          opacity: 1;
        }
      }
    }
  }
}