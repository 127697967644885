@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.toggleContainer) {
  white-space: nowrap;
}

:local(.status) {
  padding: 2px 5px 2px 8px;
  border-radius: 6px;
  color: white;
}

:local(.available) {
  composes: status;
  background-color: $colorGreen;
}

:local(.unavailable) {
  composes: status;
  background-color: $colorRed;
}

:local(.children) {
  width: fit-content;
}
