@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.linksContainer) {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  color: $colorRiverBed;
  flex: 1 1 auto;
  overflow-y: auto;
  @include desktop {
    overflow-y: visible;
  }
  font-family: 'poppins';
  font-weight: 500;
  justify-content: space-between;

  * {
    flex-shrink: 0;
  }

  i {
    margin-right: 10px !important;
  }
}

:local(.logoContainer) {
  padding: 10px 15px;
  display: flex;
  justify-content: center;
}

:local(.logoLink) {
  display: flex;
  align-items: center;
  padding: 15px;
  padding-right: 10px;
}

:local(.moreLinks) {
  width: 100%;
  padding: 20px;
  font-size: 15px;
  opacity: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  &:hover {
    > i {
      color: $colorLabstepBlue !important;
    }
  }
}

:global {
  :local(.link) {
    border-bottom: 3px solid white;
    padding: 20px;
    font-size: 13px;
    color: $colorRiverBed;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;

    i {

      color: $colorRiverBed !important;
    }

    &:hover {
      i {
        color: $colorLabstepBlue !important;
      }
    }

    &.active {
      border-bottom: 3px solid $colorLabstepBlue;
      color: $colorLabstepBlue !important;
      i {
        color: $colorLabstepBlue !important;
      }
    }
    position: relative !important;
    div {
      position: absolute;
      bottom: 20px;
      right: 10px;
    }
  }
}

:local(.iconLink) {
  composes: link;
  font-size: 15px;
  justify-content: center;
  i {
    font-size: 15px;
    color: $colorRiverBed !important;
  }
  position: relative !important;
  div {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}

:local(.border) {
  border-top: 1px solid $colorArctic;
}

:local(.deleted) {
  font-size: 11px;
  padding: 4px;
  padding-left: 35px;
  margin-bottom: 4px;
}

:local(.groupsContainer) {
  margin-top: 0px;
  margin-bottom: 10px;
}
