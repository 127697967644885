@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.folderName) {
  font-style: normal;
  margin: 0px 5px;
}

:local(.folderItem) {
  margin-right: 5px !important;
  margin-bottom: 5px !important;
  background-color: $colorArctic;
}

:local(.removeAction) {
  margin-left: 9px;
  div {
    display: inherit;
  }
}
