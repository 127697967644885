@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.dragHandle) {
  cursor: pointer;
  position: relative;
}

:local(.icon) {
  color: $colorEarlGray;
}
