:local(.container) {
  padding: 10px;
  border: 2px solid #e2a14d;
  border-radius: 3px;
  background-color: #fff7c5;
  margin-bottom: 20px;
  span {
    padding: 0 !important;
    color: blue;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: transparent;
    }
  }
}
