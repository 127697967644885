@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.selectionMode) {
  &:hover {
    background-color: $colorYellow !important;
  }
}

:local(.table) {
  background-color: $colorArctic;
}
