:local(.loading) {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

:local(.print) {
  margin: auto;
  overflow: auto;
  padding: 10px 20px;
}
