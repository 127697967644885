:local(.container) {
  .Select-Search-Container {
    // size to table cell
    min-width: auto;
    width: 100%;
    height: 100%;
  }

  .Select-Search__control {
    // ditto
    height: 100%;
  }
}
