:local(.disabled) {
  opacity: 0.3;
}

:local(.picker) {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  >div:first-child {
    width: 20px;
  }
}
