@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.border) {
  border: 1px solid $colorArctic;
  border-radius: 2px;
}

:local(.container) {
  composes: border;
  padding: 10px;
  &:hover {
    :local(.topContainer):nth-child(2) {
      display: flex;
    }
  }
}

:local(.topContainer) {
  display: flex;
  flex-wrap: wrap;

  > *:first-child {
    flex-grow: 1;
  }
  font-size: small;
}

@include desktop {
  :local(.topContainer):nth-child(2) {
    display: none;
  }
}

:local(.imageContainer) {
  padding-bottom: 10px;
  margin: -10px -10px 0px -10px;
}

:local(.image) {
  composes: border;
}

:local(.infoContainer) {
  display: flex;
  > *:first-child {
    margin-right: 5px;
  }

  /* Name */
  b {
    word-break: break-word;
  }

  font-size: smaller;
}

:local(.actions) {
  display: flex;
  flex-shrink: 0;
  margin-left: 5px;
  > * {
    margin: 0px 7px !important;
  }
}
