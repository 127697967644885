@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.dropzone) {
  text-align: baseline;
  height: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(179, 207, 252, 0.25);
  border: 1px solid rgba(0, 87, 221, 0.25);
  box-sizing: border-box;
  border-radius: 5px;
}

:local(.text) {
  align-items: baseline;
}
