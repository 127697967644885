@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.preview) {
  >div {
    margin-top: 5px;
    display: flex;
    align-items: center;
    >*:not(:last-child) {
      margin-right: 10px;
    }
  }

  span {
    color: $colorEarlGray;
  }
}