@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.logoContainer) {
  padding: 15px;
}

:local(.sidebarStyles) {
  background-color: white;
  .sidebar {
    border: none!important;
  }
}
