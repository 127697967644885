:local(.container) {
  display: flex;
  align-items: flex-end;
  button {
    height: 40px;
    width: 40px;
  }

  /* Without this mentions look funky */
  textarea {
    line-height: inherit;
  }
}

:local(.headerImage) {
  margin-right: 5px;
}

:local(.textAreaContainer) {
  flex-grow: 1;
  position: relative;
}

:local(.attachmentIcon) {
  position: absolute;
  right: 7px;
  bottom: 5px;
}

:local(.attachmentsContainer) {
  margin-top: 5px;
  display: flex;
  >* {
    margin-right: 5px;
  }
}

:local(.filesPreview) {
  margin-top: 10px;
}

:local(.sendButtonContainer) {
  padding-left: 2px;
  align-self: flex-end;
}

:local(.authenticatedUserProfileImage) {
  margin-right: 20px;
}
