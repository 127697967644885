@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.textPlaceholder) {
  color: $colorEarlGray;
}

:local(.clickable) {
  &:hover{
    color: $colorLabstepBlue;
  }
  cursor: pointer;
}
