@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.logoContainer) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: white;
  > div:last-child {
    margin-top: 20px;
  }
}

:local(.mainContent) {
  background-color: $colorArctic;
  justify-content: center;
  display: flex;
  flex: 1;
  overflow: hidden;
}

:local(.errorContent) {
  padding: 10px;
  background-color: white;
}

:local(.topBar) {
  height: 50px;
  background-color: $colorPrimary;
}

:local(.fullPageLoader) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

:local(.fullPageLoader) > div {
  margin: 10px;
}

:local(.showCard) {
  flex: 1 1 auto;
}
