@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.name) {
  font-weight: 500;
  font-size: 16px;
  padding-left: 10px;

  flex: 1;
  min-width: 0; /* or some value */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:local(.item) {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 8px 10px;
  flex-grow: 1;
}
