:local(.container) {
  width: 100%;
  background-color: white;
  margin: 7px;
  padding: 10px 20px;
  display: inline-table;
  > div:first-child {
    padding-left: 10px;
  }
}
