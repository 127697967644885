:local(.contentContainer) {
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(64, 78, 91, 0.5);
  background-color: white;
  display: flex;
  flex-direction: column;

  max-height: calc(100vh - 200px);
  overflow: auto;

  >*:first-child {
    margin-bottom: 10px;
  }

  >*:nth-child(2) {
    margin-bottom: 10px;
  }
}