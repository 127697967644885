:global {
  :local(.cell) {
    /** Remove the blue outline when the user clicks on the cell */
    border: none !important;
    outline: none;
    .ag-fill-handle {
      display: none;
    }
    background-color: initial !important;
  }
}