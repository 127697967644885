:local(.image) {
    display: block;
    margin: 10px auto;
}

:local(.footer) {
    display: flex;
    justify-content: space-between;
}

:local(.warningText) {
    margin-top: 30px;
    margin-bottom: 20px;
}