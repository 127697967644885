:local(.container) {
  text-align: center;
  > i {
    width: 100%;
  }
}

:local(.number) {
  pointer-events: none;
}
