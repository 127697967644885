@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.info) {
  display: flex;
  border-bottom: 1px solid $colorArctic;
  background-color: white;
  padding: 10px;
}

:local(.name) {
  font-weight: 500;
  cursor: pointer;
}

:local(.infoContainer) {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
:local(.actionContainer) {
  > i {
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  display: flex;
  align-items: center;
}

:local(.contentContainer) {
  border-bottom: 1px solid $colorArctic;
}

:local(.scrollable) {
  overflow: auto;
}
