:local(.info) {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow:1;
}

:global {
  :local(.image) {
    .div {
      margin: 0px;
    }
  }
}