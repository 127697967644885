@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.content) {
  display: flex;
  flex-direction: column;
}

:local(.commentList) {
  flex-grow: 1;
  margin: 15px 0px;
}

:local(.create) {
  margin-top: 5px;
  width: 100%;
  padding-bottom: 10px;
  textarea {
    max-height: 100px;
  }
}

:local(.label) {
  border-color: $colorBlue !important;
}
