@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0px;
  > * {
    margin-right: 10px;
  }
  span {
    color: $colorRiverBed;
  }
}
