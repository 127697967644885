:local(.container) {
  margin-top: 10px;
  display: flex;
  align-items: center;
  > span:first-child {
    margin-right: 15px;
  }

  > span:last-child {
    margin-left: 15px;
  }
}
