@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.iconContainer) {
  &:hover {
    background-color: $colorArctic;
  }
  height: 20px;
  width: 20px;
  padding: 2px;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: baseline;
  border-radius: 5px;
  position: relative;
  cursor: pointer;

  > i {
    margin: 0 !important;
  }
  :global(.arrow.left) {
    margin: 4px 0 !important;
  }
}


:local(.noBackground) {
  &:hover {
    background: none !important;
    filter: saturate(1.2);
  }
}

:local(.link) {
  color: $colorLabstepBlue;
}
