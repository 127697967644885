:local(.container) {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  > div {
    flex: 1 1 auto;
    padding-bottom: 60px;
  }
}
