/**
 * Labstep
 */

:local(.container) {
  min-width: 120px;
  max-width: 400px !important;
  position: absolute;
}

:local(.iconCard) {
  padding: 10px;
  margin-right: 10px;
  flex-shrink: 1;
}

:local(.icon) {
  max-height: 15px;
  max-width: 15px;
}

:local(.textContainer) {
  flex-grow: 1;
}

:local(.header) {
  display: flex;
  align-items: center;
  > div {
    margin-left: 5px !important;
  }
}
