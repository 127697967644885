@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  text-align: center !important;
  margin: auto;
  width: 570px;
  height: calc(100vh - 100px);
}
:local(.shiny) {
  background: $premiumGradient !important;
}
:local(.description) {
  width: 320px;
}
:local(.heading) {
  width: 250px;
}