:local(.clickable) {
  cursor: pointer;
}

:local(.columnHeader){
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
