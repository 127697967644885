@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.topBar) {
  height: 50px;
  display: flex;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  z-index: 3;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $colorArctic;
  > div {
    overflow: hidden;
    span {
      margin-right: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

:local(.actionContainer) {
  flex-shrink: 0;
}

:local(.header) {
  display: flex;
  align-items: baseline;
  width: 100%;
}

:local(.link),
:local(.link:hover) {
  color: inherit;
}
