@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.verticalContainer) {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: $gap-4;
  min-width: 200px;
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid $colorArctic;
}

:global {
  :local(.verticalLink) {
    font-family: 'poppins';
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    color: $colorRiverBed;
    padding: 0px 12px;

    &.active {
      color: $colorHyperlink;
    }
    display: flex;
  }
}

:local(.groupContainer) {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: $gap-4;
  min-width: 200px;
  padding-right: 12px;
  margin-right: 12px;
  // border-right: 1px solid $colorArctic;
}

.groupHeading {
  padding: 4px 12px;
  text-transform: uppercase;
  color: $colorEarlGray;
  display: flex;
  align-items: center;
  gap: 6px;

  hr {
    width: 100%;
    border: 1px solid $colorArctic;
  }
}
