:local(.seeMore) {
  margin-bottom: 10px;
  text-align: right;
}

:local(.center) {
  text-align: center;
  width: 100%;
  padding: 10px 0px;
}

:local(.container) {
  display: flex;
  flex-direction: column;
  flex-grow:1;
  &.reverse {
    flex-direction: column-reverse;
  }
}
