@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.header){
  margin-bottom: 10px;
  display: flex;
}

:local(.headerPin){
  margin-right: 20px;
}

:local(.commentBody) {
  margin-right: 10px;
}

:local(.commentItemContainer) {
  width: 100%;
}

:local(.resourceContainer) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: $colorArctic;
  margin: 5px;
  border-radius: 5px
}
:local(.simpleComment) {
  margin-bottom: 10px;
}

:local(.itemContainer){
  display: flex;
}

:local(.itemRight){
  flex-grow: 1;
  min-width: 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

:local(.itemBody){
  width: calc(100% - 25px);
}

:local(.itemLeft){
  padding: 10px;
  flex-shrink: 0;
}

:local(.itemActions){
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  float: right;
  width: 1px;
}

:local(.itemNameDate) {
  display: flex;
  align-items: baseline;
  >*:first-child {
    margin-right: 10px;
  }
  >*:last-child {
    flex-grow: 1;
  }
}

:local(.indent) {
  background-color: $colorLightGrey;
  padding: 10px;
  >div:nth-child(2) {
    margin-left: 35px;
    @include mobile {
      margin-left: 0px;
    }
  }
}
