@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.container) {
  display: flex;
  color: white!important;
  min-width: 150px;
}

:local(.messageContainer) {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 100%;
  flex-grow: 1;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

:local(.message) {
  margin-left: 10px;
  flex-grow: 1;
}

:local(.emoji) {
  font-size: 40px;
}

:local(.errorCloseColor) {
  background-color: $colorPunchDrunk;
}

:local(.successCloseColor) {
  background-color: $colorBlue;
}

:local(.infoCloseColor) {
  background-color: darken($colorArctic, 15%);
}

:local(.closeContainer) {
  display: flex;
  align-items: top;
  flex-shrink: 0;

  i {
    color: white;
    opacity: 1!important;
    transition: none!important;
  }
}
