@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.container) {
  position: sticky;
  top: 0px;
  padding-top: 15px;
  background-color: white;
  z-index: 2;
}

:local(.actionContainer) {
  align-items: center;

  @include not-mobile {
    > *:not(:first-child) {
      margin-left: 15px;
    }
  }
}

:local(.divider) {
  margin-bottom: 0;
}
