@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  padding: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100px;
  background-color: $colorArctic;

  >*:nth-child(2) {
    margin-left: 10px;
  }
}

:local(.image) {
  cursor: pointer;
}
