@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.text) {
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    :local(.preview) {
      opacity: 1;
    }
  }
}

:local(.textPosition) {
  padding-right: 27px;
}

:local(.preview) {
  opacity: 0;

  position: absolute;
  right: 5px;
  top: 5px;
}

:local(.previewPosition) {
  right: 45px;
}

:local(.actionIcon) {
  position: absolute;
  right: 18px;
}