:global {
  :local(.container) {
    .download {
      visibility: hidden;
    }
    &:hover {
      .download {
        visibility: visible;
      }
    }
  }
}

:local(.actionList) {
  composes: container from '../../Menu/File/styles.module.scss';
}
