:local(.main) {
  > p,
  > span {
    text-align: center;
  }
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;

  img {
    max-height: 250px !important;
    width: auto !important;
    margin: auto !important;
  }
}

:local(.actions) {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

:local(.withoutSecondaryAction) {
  > div {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
}

:local(.stackedActions) {
  flex-direction: column;
  > div {
    display: flex;
  }
}
