:global {
  :local(.cell) {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-overflow: clip !important;
    /** Remove the blue outline when the user clicks on the cell */
    border: none !important;
    outline: none;
    .ag-fill-handle {
      display: none;
    }
    background-color: initial !important;
  }
}