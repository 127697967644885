@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  text-align: 'center';
  margin: 'auto';
  margin-top: '10%';
  width: '570px';
}
:local(.shiny) {
  background: $premiumGradient !important;
}
:local(.button) {
  text-wrap: nowrap;
  margin: 0;
}
:local(.description) {
  width: 320px;
}
:local(.heading) {
  width: 250px;
}