:local(.container) {
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 1 1 45%;
    flex-shrink: 0;
    margin-bottom: 10px;
    height: 250px;
    min-width: 275px;

    &:nth-child(odd) {
      margin-right: 10px;
    }
  }
}
