@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.assigned) {
  color: $colorYellow;
}

:local(.container) {
  display: flex;
  justify-content: space-between;
  > div:first-child {
    margin-right: 5px;
  }
}
