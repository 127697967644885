:local(.card) {
  padding: 10px 50px !important;
  position: relative;
  min-height: 45px;
  margin-bottom: 5px;
}

:local(.completeStep) {
  position: absolute;
  left: 10px;
  top: 10px;
}

:local(.actions) {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 15px;
  align-items: center;
}

:global {
  :local(.showThread) {
    position: relative;
    width: min-content;
    margin-top: 10px;
    cursor: 'pointer';

    .label {
      z-index: 1 !important;
    }
  }
}

:local(.divider) {
  position: absolute;
  width: 100%;
  bottom: -10px;
  left: -10px;

  > div {
    position: relative;
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    border-bottom-color: black;
    width: 100%;

    > button {
      position: absolute;
      left: -20px;
      top: -15px;
    }
  }
}

:local(.actionButton) {
  &:hover {
    background: none !important;
    filter: saturate(1.2);
  }
}

:local(.unwrapStep) {
  position: absolute;
  right: 5px;
  top: 5px;
}
