@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.buttonContainer) {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  > * {
    margin: 0px 10px !important;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
  }
}

:local(.container) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
