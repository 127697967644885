@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.toggle) {
  background-color: $colorArctic;
  padding: 6px;
  border-radius: $defaultBorderRadius;
  width: fit-content;
  gap: $gap-2;
}

:local(.container) {
  gap: $gap-2;
  white-space: nowrap;
}
