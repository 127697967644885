@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.status) {
  padding: 2px 5px 2px 8px;
  border-radius: 6px;
  margin-right: 10px;
  font-weight: 500;
}

:local(.unstarted) {
  composes: status;
  background-color: $colorArctic;
}

:local(.started) {
  composes: status;
  background-color: $colorYellow;
  color: white;
}

:local(.completed) {
  composes: status;
  background-color: $colorGreen;
  color: white;
}
