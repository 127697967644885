@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.sidebar) {
  display: flex;
  cursor: pointer;
  align-items: center;

  i {
    width: 20px !important;
    text-align: left !important;
    height: 20px !important;
    font-size: 20px !important;
    margin: 0px 8px 0px 0px !important;
  }
}