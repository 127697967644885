@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  border: 1px solid transparent;
  cursor: pointer;
  color: $colorRiverBed;
  padding: 5px;
  font-weight: 500;
  border-radius: 5px;
  &:hover {
    background-color: $colorArctic;
  }
}

:local(.text) {
  text-align: left;
  width: 100%;
  margin-left: 5px;
}

:local(.disabled) {
  opacity: 0.45;
  cursor: default;
  &:hover {
    background-color: inherit;
  }
}
