/**
 * Labstep
 */

@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  border: 1px solid $colorArctic;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 1px 1px $colorArctic;
  min-width: 120px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  padding: 0;
}

:local(.item) {
  display: flex;
  white-space: nowrap;
  > div:first-child {
    flex-grow: 1;
    margin-right: 10px;
  }
}
