@import 'src/labstep-web/scss/libs/_vars.scss';


:local(.header){
    display: flex;
    justify-content: space-between;
  }

.shinyButton {
    background: $premiumGradient !important;
}