@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  height: 400px;
}

:local(.active) {
  text-decoration: underline;
  color: $colorPrimary;
}

:local(.categoryFilterContainer) {
  >div {
    cursor: pointer;
    padding: 0px 20px;
    margin: 8px 0px;
  }
}

:local(.right) {
  width: 310px;

  >*:first-child {
    margin-bottom: 5px;
  }
}

:local(.charactersContainer) {
  background-color: white;
  height: min-content;
  max-height: 300px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;

  >div {
    height: 30px;
    width: 30px;
    &:hover {
      background-color: rgba(58, 120, 255, 0.1);
    }
  }
}

:local(.symbol) {
  cursor: pointer;
  padding: 5px;
  width: 25px;
  font-size: 16px;
}
