@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.initials) {
  span {
    font-family: poppins;
    font-weight: 600;
    color: white !important;
  }

  border-radius: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
  user-select: none;
}

:local(.square) {
  border-radius: 20% !important;
  border: 0 !important;
}

:local(.mini) {
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  width: 30px;
}

:local(.tiny) {
  font-size: 18px;
  line-height: 40px;
  height: 50px;
  width: 50px;
}

:local(.small) {
  font-size: 42px;
  line-height: 40px;
  height: 85px;
  width: 85px;
}

:local(.medium) {
  font-size: 60px;
  line-height: 50px;
  height: 120px;
  width: 120px;
}

:local(.theme1) {
  background-color: $colorYellow;
}

:local(.theme2) {
  background-color: $colorPurple;
}

:local(.theme3) {
  background-color: $colorBlueGreen;
}

:local(.theme4) {
  background-color: $colorOrange;
}

:local(.theme5) {
  background-color: $colorGreen;
}

:local(.theme6) {
  background-color: $colorBlue;
}

:local(.theme7) {
  background-color: $colorRed;
}

:local(.theme8) {
  background-color: $colorRiverBed;
}

:local(.centered) {
  margin: 0;
}
