@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.icon) {
  margin-left: 5px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
    color: $colorPrimary;
  }
}

:local(.customShow) {
  display: flex;
  i {
    visibility: hidden;
  }
  &:hover {
    i {
      visibility: visible;
    }
  }
}