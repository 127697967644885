@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.actionsBar) {
  justify-content: space-between;
  flex-shrink: 0;
  display: flex;
  align-items: center;

  > div:first-child {
    flex-grow: 1;
  }
}
