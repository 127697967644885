@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.container) {
  composes: container from '../../../ExperimentWorkflow/Show/Center/styles.module.scss';
}

:local(.infoContainer) {
  composes: infoContainer from '../../../Resource/Show/Center/styles.module.scss';
}

:local(.info) {
  flex-grow: 1;
}

:local(.image) {
  margin-right: 10px;
}

:local(.actionCreateContainer) {
  margin-bottom: 10px;
}
