/**
 * Labstep
 */

@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  border-radius: 15px;
  padding: 3px;
  position: absolute;
  color: white;
  background-color: #424E5A;
  z-index: 1000;

  button {
    background: transparent !important;

    span {
      color: white;
      margin-right: 5px;
    }
    i {
      color: white;
    }
  }
}
