@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.help) {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

:local(.preview) {
  line-height: normal;
}
