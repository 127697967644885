@import 'src/labstep-web/scss/libs/_vars.scss';

:global {
  :local(.saveOnChange) {
    .ui.form .field {
      margin-bottom: 0px!important;
    }
    input {
      line-height: 1.6em!important;
      width: auto!important;
    }
    input:not(:hover):not(:focus) {
      border-color: transparent!important;
      background-color: inherit!important;
    }

  }
}

:local(.header) {
  input {
    font-size: 22px!important;
    line-height: 30px!important;
    font-family: 'poppins'!important;
    color: $colorDefaultHeader!important;
    font-weight: 500!important;
    padding-left: 10px!important;
    margin-bottom: 5px!important;
  }
}