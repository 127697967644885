:local(.info) {
  padding: 10px;
}

:local(.image) {
  > div {
    margin: 0px;
  }
}

:local(.resource) {
  padding-left: 10px;
}

:local(.metadata) {
  margin-top: 0px !important;
}
