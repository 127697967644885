@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.buttonGroup) {
  display: flex;
}

:local(.date) {
  color: $colorEarlGray;
}

:global {
  :local(.card) {
    &.read {
      background-color: #F7F9FB;
    }
  }
}