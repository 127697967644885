@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.container) {
  &:hover {
    :local(.dropdown) {
      max-height: 500px;
    }
  }
}

:local(.dropdown) {
  background-color: white;
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;
  width: 255px;
  position: absolute;
  top: 49px;
  overflow: auto;
  border-radius: 0 0 $defaultBorderRadius $defaultBorderRadius;
  filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.2));
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  z-index: 1000;
}
