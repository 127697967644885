@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.item) {
  background-color: $colorArctic;
  padding: 10px;
}

:local(.bold) {
  font-weight: bold;
}

:local(.dropdown) {
  height: 20px
}


:local(.cardSigned) {
  border-left-color: $colorGreen;
}

:local(.cardRejected) {
  border-left-color: $colorRed;
}

:local(.cardRejected),:local(.cardSigned) {
  border-left-width: 6px;
  @include desktop {
    min-width: 400px;
  }
}