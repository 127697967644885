@import 'src/labstep-web/scss/libs/_vars.scss';

.Select-Search__control {
  min-height: 30px !important;
  border-color: $colorArctic !important;
  &:hover {
    border-color: $colorSilverSurfer !important;
  }
}

.Select-Search__menu {
  z-index: 1000 !important;
}

.Select-Search__indicator {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.Select-Search__value-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.Select-Search-Container {
  min-width: 160px;
}

.Select-Search__group-heading {
  font-size: 13px !important;
  text-transform: initial !important;
}

