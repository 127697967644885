@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.divider) {
  width: 10px;
  margin-right: 5px;
  border-radius: 0 5px 5px 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: $colorBlue;
  }
  cursor: w-resize;
  border-right: 1px solid $colorArctic;
  border-left: 1px solid $colorArctic;
}

:local(.collapsed) {
  cursor: e-resize !important;
}
