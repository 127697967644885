@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.content) {
  padding: $p-4;
  gap: $p-4;
  width: 100%;
  height: 100%;
}

:local(.card) {
  margin-left: 0px !important;
  border: 1px solid $colorArctic !important;
  box-shadow: none !important;
  cursor: pointer;


  &.disabled {
    background-color: $colorAthensGray;
    box-shadow: none;
    border: none;
    cursor: not-allowed;
  }

  &.selected {
    background-color: $colorLightGrey;
    box-shadow: inset 0px 2px 4px $colorArctic;
  }

  &.noHover {
    pointer-events: none;
  }
}

:local(.header) {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  &.disabledHeader {
    color: $colorEarlGray;
  }

  &.selectedHeader {
    color: $colorLabstepBlue;
  }
}