@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    align-content: center;
    max-width: 460px;
    gap: 6px;
}

:local(.plan) {
    margin: 20px 0;
    text-align: center;
    justify-content: center;
}

:local(.userCounter) {
    width: 227px;
    text-align: center;

    >div:nth-child(2) {
      display: flex;
      color: $colorEarlGray;
      font-size: 42px;
      font-weight: 600;
      justify-content: center;
      margin: 5px 0;
    }
}

:local(.seatAdjustButton) {
    cursor: pointer;
    border: 1px solid $colorArctic;
    color: $colorEarlGray;
    padding: 0 12px;

    &:hover {
      background-color: $colorArctic;
    }
}

:local(.selectedSeatCount) {
    border: 1px solid $colorArctic;
    border-width: 1px 0;
    color: $colorBlueGreen;
    padding: 0 12px,
}

:local(.amount) {
    color: $colorEarlGray;
}

:local(.totalPrice){
    display: flex;
    align-items: center;
    justify-content: center;
}

:local(.price) {
    font-size: 42px;
    font-weight: 600;
    padding-right: 5px;
    color: $colorGreen;
}

:local(.interval) {
    color: $colorEarlGray;
    font-weight: 600;
}

:local(.message) {
   
    border-radius: 4px !important;
    padding: 10px !important;
    text-align: left;
}
