@import 'src/labstep-web/scss/libs/_vars.scss';

.statusCard {
    width: 220px;
    border-radius: 6px;
    box-shadow: none;
    padding-right: 4px;

    i {
        margin: 0 !important;
    }
    &.disableHover {
        &:hover {
            cursor: default; // or any other style you want to apply instead of grab
        }
    }
}

.statusCard:hover {
    cursor: grab;
}

.selectedBorder {
    border: 1px solid #2089C9;
}

.unselectedBorder {
    border: 1px solid #DBE0E8;
}

.iconPanel {
    padding: 12px 4px;
    border-radius: 5px 0 0 5px;
}

.textContent {
    white-space: nowrap;
    font-weight: 500;
    text-wrap: wrap;
}

.secondaryText {
    color: $colorEarlGray;
    font-size: 11px;
}

.trashIcon :hover {
    cursor: pointer;
}