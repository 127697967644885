@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.actionLeft) {
  position: absolute;
  left: 0.75em; // match Semantic modal close icon
}

:local(.actionGroup) {
  display: flex;
  gap: $gap-8;
}

:local(.action) {
  display: flex;
  gap: $gap-2_5 !important;
}

:local(.error) {
  background: none !important;
  pointer-events: none !important;
  font-weight: initial !important;
}

:local(.continueText){
  margin: 0 20px;
}
