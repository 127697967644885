:local(.container) {
  label {
    font-weight: bold;
  }
  > div {
    word-wrap: break-word;
    & > span {
      margin-right: 5px;
    }
  }
}

:local(.pictograms) {
  margin-bottom: 5px;
}

:local(.inline) {
  display: flex;
  align-items: center;
  img {
    align-self: center;
  }
}

:local(.codeList) {
  list-style-type: none; /* Remove bullets */
  > li {
    margin-bottom: 0.25em;
  }
}
