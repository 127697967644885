@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.breakContainer) {
  display: flex;
  align-items: center;
  height: 30px;
  margin: 2px;
}

:local(.break) {
  height: 72%;
  opacity: 0.5;
  border-left: 1px solid $colorEarlGray;
}
