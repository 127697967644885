:local(.plansContainer) {
  height: 485px;
  display: flex;
  justify-content: space-between;
  gap: 26px;
}

:local(.footer) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:local(.container) {
  display: flex;
  flex-direction: column;
  gap: 24px;
  > div:nth-child(2) {
    flex-grow: 1;
  }
}
