@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.card) {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 100px;
  align-items: center;
  display: flex;
  width: 100%;
  >* {
    width: 100%;
  }

  @include mobile {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}