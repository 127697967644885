@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.header) {
    font-size: 18px;
    color: $colorRed;
}

:local(.closeButton) {
    border: 1px solid $colorRed !important;
    color: $colorRed !important;
}