@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
};

:local(.headerContainer) {
  flex-shrink: 0;
  display: flex;
  background-color: white;
  padding: 15px;
  height: 50px;
  justify-content: space-between;

  div i {
    width: 16px !important;
    height: 16px !important;
    font-size: 16px !important;
    margin-left: 8px;
  }
}

:local(.viewAllContainer) {
  flex-shrink: 0;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

:local(.header) {
  margin-right: 5px;
  margin-bottom: 0px!important;
}

:local(.notificationListContainer) {
  padding: 20px;
  overflow: auto;
  background-color: white;
  flex-grow: 1;

  &::-webkit-scrollbar-thumb {
    background: $colorSilverSurfer;
  }
}

:local(.listItem) {
  margin-bottom: 15px;
}

:local(.moreCard) {
  text-align: center;
  background-color: $colorPrimary!important;
  padding: 14px;
}

:local(.header) {
  word-break: none;
}
