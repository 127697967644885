@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.pointer) {
  cursor: pointer;
}

:local(.container) {
  margin: 5px 0px;
}

:local(.metadataLabel) {
  color: $colorRiverBed !important;
  cursor: pointer;
  font-weight: 500;
}

:local(.metadataValue) {
  > div:first-child {
    display: flex;
    align-items: baseline;
    flex-shrink: 0;
    min-width: 200px;
    width: 100%;
    >div {
      flex-grow:1;
    }
  }
}

:local(.metadataComponentContainer) {
  border: 1px solid $colorArctic;
  border-radius: 3px;
  display: flex;
  width: fit-content;
  align-items: center;

  > div {
    padding: 10px;
  }

  > div:first-child {
    border-right: 1px solid $colorArctic;
  }
}

:local(.fullWidth) {
  flex-direction: column;
  align-items: initial;

  > div:first-child {
    border-right: none;
    border-bottom: 1px solid $colorArctic;
  }
}

:local(.selected) {
  box-shadow: 0 0 0 2px $colorLabstepBlue;
}
