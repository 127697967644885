@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

#default-modal {
  max-width: 100%;
  @include desktop {
    max-height: auto;
  }
  max-height: 100%;
}

#default-modal.fullscreen {
  top: 0;
  bottom: 0;
}
