@import 'src/labstep-web/scss/libs/_vars.scss';

.status {
  font-weight: normal !important;
  color: $colorBrightWhite;
}

.statusContent {
  padding: 4px 12px;
  height: 28px;
  font-weight: 500;
  gap: 4px;
}

.statusContent:hover {
  cursor: pointer;
}

.dividerComponent {
  width: 5px;
  height: 28px;
}

.chevron {
  flex: 1 0 0;
  align-self: stretch;
  background-color: $colorArctic;
}

.deactivatedSection {
  background-color: $colorArctic;
  color: $colorEarlGray;
}

.startSection {
  border-radius: 6px 0px 0px 6px;
}

.endSection {
  border-radius: 0px 6px 6px 0px;
}

.smallSection {
  min-width: 50px;
}

.path {
  fill: $colorArctic;
  stroke-dasharray: 50;
  stroke-dashoffset: -28;
  stroke: $colorMidGrey;
}
