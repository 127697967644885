@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.card) {
  padding: 10px;
  display: flex;
  min-width: 250px;
  align-items: baseline;
  cursor: pointer;
  > div:first-child {
    flex-grow: 1;
    display: flex;
    align-items: baseline;
  }
}

:local(.logoContainer) {
  padding-right: 5px;
}

:local(.inlineFrame) {
  height: 800px;
  display: flex;
  border: 1px solid $colorArctic;
  overflow: auto;
}
