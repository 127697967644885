@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.triggerContainer) {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: $colorYellow;
  display: flex;
  justify-content: center;
  &:hover {
    filter: saturate(1.2);
  }
}

:local(.dot) {
  position: absolute;
  bottom: -4px;
  right: -4px;
  border-radius: 100%;
  padding: 2px;
  background-color: white;

  > div {
    border-radius: 100%;
    background-color: $colorRed;
    width: 8px;
    height: 8px;
  }
}

:local(.container) {
  flex-grow: 1;
  height: 100%;
  padding: 10px;
}

:local(.list) {
  overflow: auto;
  height: 100%;
}

:local(.actionContainer) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    font-weight: bold;
  }
}
