@import 'src/labstep-web/scss/libs/_vars.scss';

.label {
  color: white !important;
  i {
    margin-right: 4px !important;
  }
  display: flex !important;
  align-items: center !important;
  word-break: keep-all !important;
  white-space: nowrap !important;
}

:local(.draft) {
  background-color: $colorOrange !important;
  composes: label;
}

:local(.live) {
  background-color: $colorGreen !important;
  composes: label;
}

:local(.superseded) {
  background-color: brown !important;
  composes: label;
}

:local(.abandoned) {
  background-color: $colorCrimsonRed !important;
  composes: label;
}

.container {
  display: flex;
  align-items: center;
  > span {
    color: $colorEarlGray;
    font-weight: 600;
    margin-right: 5px;
  }
}
