@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.card) {
  display: flex;
  flex-direction: column;
}

:local(.div) {
  text-align: right;
  align-self: flex-end;
}

:local(.container) {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

:local(.name) {
  flex-grow: 1;
}

:local(.info) {
  @include desktop {
    flex-direction: row !important;
  }
}
