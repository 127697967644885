:local(.sliderEnter) .transition-page {
  transform: translate3d(100%, 0, 0);
}

:local(.sliderEnterDone) .transition-page {
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease;
}

:local(.sliderExitActive) .transition-page {
  transform: translate3d(100%, 0, 0);
  transition: all 500ms ease;
}

:local(.container) {
  position: absolute;
  z-index: 999;
  width: 100%;
}
