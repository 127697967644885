@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.body) {
  td {
    overflow: visible !important;
  }
}

:local(.header) {
  font-size: 11px;
}

:local(.sectionRow) {
  display: flex;
  gap: 20px;
}

:local(.borderless) {
  border: none !important;

  td {
    border: none !important;
  }

  @include not-mobile {
    tbody:not(:last-child) tr:last-child td {
      border-bottom: 1px solid $colorArctic !important;
    }
  }
}
