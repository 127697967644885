:global {
  :local(.form) {
    .inline.fields > .field {
      flex-grow: 1;
    }
    flex-grow: 1;
}
}

:local(.message) {
  margin: 0 0 1em;
}
