@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.mention) {
    position: relative;
    z-index: 1;
    color: $colorLabstepBlue;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
      -1px -1px 1px white;
    pointer-events: none;

    @include mobile {
      font-size: 16px!important;
    }
}

:local(.suggestion) {
  display: flex;
  align-items: center;
  >*:first-child {
    margin-left: 0px!important;
    margin-right: 10px!important;
    flex-shrink: 0;
  }
  span {
    word-wrap: break-word;
    word-break: break-all;
  }
}

:local(.container) {
  textarea {
    margin: 0px!important;
  }

  >div >div >div >* {
    @include mobile {
      font-size: 16px!important;
    }
  }
}