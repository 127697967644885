@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.trigger) {
  padding-left: 5px;
  background-color: $colorArctic;
  border-radius: 10px;

  i {
    margin-left: 10px;
  }
  &:hover {
    filter: brightness(0.9);
  }
}
