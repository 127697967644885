@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';


:global {
  :local(.dropdown) {
    i.dropdown.icon {
      display: none;
    }

    @include desktop {
      z-index: 2;
    }
  }
}

:local(.dropdownIcon) {
  &:hover {
    i {
      color: $colorLabstepBlue;
    }
  }
}
