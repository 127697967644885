@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.name) {
  cursor: pointer;
  color: $colorHyperlink;
  font-weight: bold;
}

:local(.tableContainer) {
  max-width: 800px;
  margin: 10% auto;
}