@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.elementCard) {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  color: $colorRiverBed;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    &:first-child {
      flex: 1 1 auto;
    }
  }
  i {
    margin-right:10px;
  }
  &:hover :local(.dragHandle) {
    display: block;
  }
}

:local(.elementCardDetail) {
  flex: 1 1 auto;
  > div:last-child {
    flex: 1 1 auto;
  }
}

:local(.elementCardName) {
  font-weight: 500;
  margin-right: 10px;
  display: flex;
}

:local(.elementCardRight) {
  align-items: baseline !important;
  flex-shrink: 0;
  width: max-content;
}

:local(.clickable) {
  cursor: pointer;
}

:local(.link) {
  cursor: pointer;
  &:hover {
    * {
      color: $colorLabstepBlue;
    }
  }
}

:local(.container) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:local(.dragHandle) {
  display: none;
  position: absolute;
  left: -11px;
}
