@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.actionCreateContainer) {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

 
  > * {
    margin: 0 5px 0px 5px;
}
}
