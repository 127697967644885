@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.border) {
  box-shadow: 0px 1px 1px $colorArctic;
}

:local(.padding) {
  padding: 16px;

  @include mobile {
    padding: 8px;
  }
}

:local(.card) {
  composes: border;
  composes: padding;
  background-color: white;
  border-radius: $defaultBorderRadius;
  border: 1px solid $colorArctic;
}
