@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.infoMessage) {
  color: white;
  font-size: 20px;
  font-weight: 600;
  line-height: 33px;
}

:local(.info) {
  padding: 80px 20px;
  width: 100%;
  background-color: $colorLabstepBlue;
  display: flex;
  align-items: center;
  > * {
    flex: 1;
    flex-shrink: 0;
  }
  img {
    max-width: 250px !important;
    margin: auto;
  }
}

:local(.quote) {
  > p:first-child {
    font-style: italic;
    max-width: 500px;
  }
}
