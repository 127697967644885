@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.container) {
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  padding: 5px;
}

:local(.center) {
  display: flex;
  overflow: auto;
  flex-grow: 1;
  flex-direction: column;
  direction: rtl;
  > div {
    direction: ltr;
    flex: 1 1 auto;
  }
  #information-card {
    border-radius: 5px 0px 0px 5px;
    border: none;
  }
}
