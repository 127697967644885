/**
 * Library overrides
 */

.flatpickr-calendar {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

// Image annotation
.doka--button-action-confirm {
  color: white !important;
  background-color: #005bcc !important;
}

.doka--button-action-confirm:focus,
.doka--button-action-confirm:hover {
  background-color: #0057dd !important;
}


// Ketcher overwrite
.ToolbarMultiToolItem-module_portal__yDg5_ {
  z-index: 1000 !important;
}
