@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.title) {
  text-align: left !important;
  align-self: center;
}

:local(.header) {
  display: flex !important;
  justify-content: space-between;
  padding: $p-2_5 $p-5 !important;
  padding-right: $p-5 !important; // prevent moving on mobile
  > div {
    flex-basis: calc(33.33% - 10px);
    text-align: center;
  }
}
