@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  padding: 5px;
  height: 30px;
  align-items: center;
  border-radius: 5px;
  line-height: 1em;
  &:hover {
    background-color: $colorArctic;
  }
}
