@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.header) {
  font-family: 'poppins';
  color: $colorDefaultHeader;
  font-weight: 500;
  display: block;
  word-break: break-word;
}

:local(.hugeSize) {
  composes: header;
  margin-bottom: 10px;
  font-size: 50px;
  line-height: 70px;
}

:local(.xlargeSize) {
  composes: header;
  margin-bottom: 10px;
  font-size: 42px;
  line-height: 60px;
}

:local(.largeSize) {
  composes: header;
  margin-bottom: 10px;
  font-size: 34px;
  line-height: 50px;
}

:local(.mediumSize) {
  composes: header;
  margin-bottom: 10px;
  font-size: 26px;
  line-height: 40px;
}

:local(.smallSize) {
  composes: header;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 30px;
}

:local(.xsmallSize) {
  composes: header;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
}

:local(.tinySize) {
  composes: header;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
}

:local(.miniSize) {
  composes: header;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
}

:local(.white) {
  color: white;
}

:local(.red) {
  color: red;
}

:local(.noMargin) {
  margin-bottom: 0px !important;
}

.sectionHeader {
  padding: 4px 0px;
  text-transform: uppercase;
  color: $colorEarlGray;
  display: flex;
  align-items: center;
  gap: 6px;

  hr {
    flex-grow:1;
    border: 1px solid $colorArctic;
  }
}