@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:global {
  :local(.active) {
    i.grey,
    span {
      color: $colorLabstepBlue !important;
      font-weight: 500;
    }
  }
}
:global {
  :local(.container) {
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 5px !important;

    @include desktop {
      &:hover {
        i.grey,
        :local(.text) span {
          color: $colorLabstepBlue !important;
        }
      }

      &:hover {
        i.ellipsis {
          visibility: visible;
        }
      }

      i.ellipsis {
        visibility: hidden;
      }
    }
  }
}

:local(.innerContainer) {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  flex-grow: 1;
  > div:first-child {
    display: flex;
    align-items: center !important;
  }
}

:local(.text) {
  margin-left: 5px;
  font-weight: 500;
}

:local(.subFilter) {
  margin-left: 10px;
}