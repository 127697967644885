@import 'src/labstep-web/scss/libs/_vars.scss';

.groupOption {
  padding-top: 4px;
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.groupOption > :last-child {
  margin-left: auto;
  cursor: pointer !important;
}

.optionLabel {
  color: $colorLightBlack;
  font-weight: 400;
    line-height: 1;
    margin-bottom: 4px;
}

.option-description {
  color: $colorRiverBed;
  font-size: 11px;
  line-height: 1;
}