@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.card) {
  border-top: none;
  > div:nth-child(2) {
    padding: 0;
  }
}

:local(.topMenu) {
  top: 70px;
}
