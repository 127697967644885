@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.toggleContainer) {
  white-space: nowrap;
}

:local(.status) {
  padding: 2px 8px 2px 8px;
  border-radius: 6px;
  color: white;
}

:local(.open) {
  composes: status;
  color: inherit;
  background-color: $colorArctic;
}

:local(.pending) {
  composes: status;
  background-color: $colorYellow;
}

:local(.completed) {
  composes: status;
  background-color: $colorGreen;
}
