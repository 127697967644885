:local(.help) {
  display: flex;
  width: 480px;
  padding: 0px 60px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-left: 1px solid #eff2f7;
  background: #f9f9f9;
}

:local(.helpContent) {
  display: flex;
  width: 360px;
  padding: 20px;
  flex-direction: column;
  gap: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #eff2f7;
  background: #fff;
  position: relative;
  line-height: initial;
}

:local(.image) {
  position: absolute !important;
  right: -30.488px;
  bottom: -40px;
  width: 60.488px;
  height: 80px;
}
