@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.top) {
  display: flex;
}

:local(.header) {
  padding: 10px;
  flex-grow: 1;
}

:local(.secondaryInfoContainer) {
  background-color: $colorArctic;
}

:local(.secondaryInfo) {
  padding: 10px;
}

:local(.toggleMessage) {
  padding: 5px 0px;
  cursor: pointer;
  text-align: center;
  color: $colorEarlGray;
}
