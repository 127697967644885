@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.separation) {
  >* {
    margin-bottom: 10px;
  }

  >*:last-child {
    margin-bottom: 5px!important;
  }

  padding: 0px!important;
}

:local(.doubleSpaced){
  >* {
    margin-bottom: 20px;
  }
}