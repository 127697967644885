:local(.container) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

:local(.list) {
  flex-grow: 1;
  overflow: auto;
}