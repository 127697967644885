@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.label) {
  background: linear-gradient(102deg, #9747ff 0%, #80caff 100%);
  border-radius: 5px;
  padding: 2px;

  > div {
    background: white;
    border-radius: 3px;
    padding: 3px 5px;
    font-weight: 600;
    line-height: normal;
  }
}

:local(.container) {
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
