@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.active) {
  background-color: $colorBrightWhite;
  border-radius: $defaultBorderRadius;
}

:local(.item) {
  gap: $gap-2;
  cursor: pointer;
  padding: $p-1_5 $p-4 $p-1_5 $p-4;
  height: 36px; /* Fixed so active container height stays consistent */
  display: flex;
  align-items: center;
}

:local(.item:hover):not(:local(.active)) {
  background-color: $colorBrightWhite50;
  border-radius: $defaultBorderRadius;
}
