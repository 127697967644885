@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.eventCard) {
  padding: 8px;
  overflow: hidden;
  height: 100%;
  background-color: $colorPrimary;
  border: 0px;

  * {
    color: white;
  }
}

:local(.shortBooking) {
  padding-top: 0px;
}

:local(.title) {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > *:first-child:not(span) {
    flex-shrink: 0;
    margin-right: 10px !important;
  }

  > span:nth-child(2) {
    flex-grow: 1;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

:local(.disabled) {
  opacity: 0.5;
  pointer-events: none;
}
