@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.cardHeader) {
  display: flex;
  @include mobile {
    flex-direction: column;
  }
}

:local(.headerSuperContainer) {
  position: relative;
}

:local(.main) {
  display: flex;
  > *:first-child {
    flex-shrink: 0;
  }
  > *:nth-child(2) {
    padding-left: 5px;
  }
  > *:last-child {
    flex: 1 1 auto;
  }
}

:local(.headerContainer) {
  @include desktop {
    height: 100%;
  }
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  align-items: center;
}

:local(.headerImageMain) {
  align-self: normal;
  flex-grow: 1;
  display: flex;
  width: 100%;
  /* overflow-x: auto; */
}

:local(.headerImage) {
  margin-right: 10px;
  flex-shrink: 0;
}

:local(.headerMain) {
  word-break: break-word;
  @include desktop {
    flex-grow: 1;
  }
}

:local(.fluid) {
  flex-grow: 1;
}

:global {
  :local(.headerDetailsPrimary) {
    display: flex;
    flex-direction: column;
    @include desktop {
      flex-grow: 1;
    }

    &.reverse {
      flex-direction: column-reverse;
    }
  }
}

:local(.headerDetailsHeader) {
  margin-bottom: 2px !important;
}

:local(.headerSubTitle) {
  color: $colorEarlGray;
}

:local(.headerDetailsSecondary) {
  word-break: break-word;
  display: none;
  flex-shrink: 0;

  @include desktop {
    padding: 0px 10px;
    border-left: 1px solid $colorArctic;
    border-right: 1px solid $colorArctic;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

:local(.body) {
  margin-top: 10px;
}

:local(.footer) {
  margin-top: 10px;
}

:local(.body) {
  padding: 10px 0px;
  margin-bottom: 10px;
}

:local(.headerRight) {
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
}

:local(.actionMenu) {
  align-items: flex-end;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  > div:first-child {
    width: 15px;
  }
}

:local(.mainAction) {
  padding: 10px 20px;
}

:local(.mobileMainAction) {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

:local(.headerHeader) {
  margin-top: 5px;
}
