@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.plan) {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

:local(.priceContainer) {
    width: 220px !important;
}

:local(.info) {
    font-weight: 600;
    font-size: 16px;
}

:local(.price) {
    padding: 2px 0;
}

:local(.message) {
    border-radius: 4px !important;
    padding: 10px !important;
    text-align: left;
}