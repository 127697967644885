@import 'src/labstep-web/scss/libs/_vars.scss';

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .box {
    z-index: 2;
    width: 100%;
    >div{
      margin-bottom: 16px;
    }
  }

  .stateBox {
    border: none;
    height: 28px;
    padding: 4px 12px;
    border-radius: 6px;
    gap: 4px;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    display: flex;
    color: $colorRiverBed;
    background-color: $colorArctic;
    font-weight: 500;
    z-index: 1;
    margin-bottom: 16px;
  }

  .container::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 16px;
    left: 50%;
    width: 1px;
    background-color: $colorEarlGray;
    transform: translateX(-50%);
    z-index: 0;
  }

