@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.context) {
  color: $colorLabstepBlue;
  font-weight: 500;
  font-family: 'poppins';
  font-size: 16px;
  flex: 1;
  min-width: 0; /* or some value */
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* i {
    margin-left: 1em !important;
  } */
  > span {
    margin-left: 5px;
  }
}

:local(.dropdown) {
  padding: 10px;
  padding-top: 0px;
  font-weight: 500;
  font-family: 'poppins';
  font-size: 16px;
  > div {
    padding: 10px;
  }
}

:local(.dropdownItem) {
  &:hover {
    background-color: $colorArctic;
  }
}

:local(.link) {
  display: flex;
}
