@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  position: relative;
}

:local(.dot) {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  padding: 2px;
  background-color: white;

  > div {
    border-radius: 100%;
    background-color: $colorYellow;
    width: 8px;
    height: 8px;
  }
}
