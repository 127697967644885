@import 'src/labstep-web/scss/libs/_vars.scss';

:global {
  :local(.container) {
    table {
      border-collapse: collapse !important;
      width: fit-content !important;
      display: block;
      white-space: nowrap;
      overflow: visible;

      th {
        z-index: 2;
        position: sticky;
        top: 0px; /* 0px if you don't have a navbar, but something is required */
        background: white;
      }

      td.three.wide,
      .collapse-header {
        position: sticky;
        left: 0px;
        z-index: 1;
        background: white;
      }

      tbody {
        border-top: 1px solid $colorArctic;
      }
    }
  }
}
