@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.underline) {
  cursor: pointer;
  text-decoration: underline;
  * {
    text-decoration: none !important;
  }
  font-weight: 400;
  &:hover {
    color: $colorLabstepBlue;
  }
}

:local(.action) {
  cursor: pointer;
  color: $colorRiverBed;
  padding: 5px;
  font-weight: 500;
  border-radius: 5px;
  &:hover {
    background-color: $colorArctic;
  }
}

:local(.gridCell) {
  cursor: pointer;
  &:hover {
    background: none;
    color: $colorLabstepBlue;
  }
  text-overflow: ellipsis;
  overflow: hidden;
}

:local(.placeholder) {
  color: $colorEarlGray;
  font-weight: 400;
}

:local(.disabled) {
  opacity: 0.45;
  cursor: default;
  &:hover {
    background-color: inherit;
  }
}

:local(.showEdit) {
  composes: placeholder;
  border-radius: 3px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
}
