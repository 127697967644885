@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:global {
  :local(.container) {
    display: flex;
    border: 1px solid transparent;
    border-radius: 3px;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid $colorArctic;
    padding: 5px;

    > i {
      padding: 0px 5px;
      margin-top: -3px;
      margin-left: 10px;
    }

    @include desktop {
      i.times {
        visibility: hidden;
      }
    }

    &:hover {
      border: 1px solid $colorArctic;
      transition: border-color 100ms;

      i.times {
        visibility: visible;
      }
    }
  }
}

:local(.searchSelectContainer) {
  min-width: 200px;
}

:local(.content) {
  display: flex;
}
