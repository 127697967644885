@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.option) {
  display: flex !important;
  align-items: center;
  > div:first-child {
    flex-grow: 1;
    margin-right: $m-4;
  }
}
