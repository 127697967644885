@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.childComponent) {
  background: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  position: relative;
  width: 100%;
  height: 100%;
}

:local(.trigger) {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
}

:local(.resourceLocationComponent) {
  display: flex;
  align-items: center;
  height: 100%;
}
