@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.smallText) {
  font-style: italic;
  font-weight: 400;
  font-size: 10.64693px;
  line-height: 13px;
}

:local(.text) {
  font-style: normal;
  font-weight: 400;
  font-size: 11.64693px;
  line-height: 14px;
}

:local(.card) {
  width: 135px;
  height: 80px;
}