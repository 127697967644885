@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.pill) {
  display: flex;
  gap: 4px;
  padding: 2px 8px 2px 8px;
  border-radius: 6px;
  background-color: $colorArctic;
  width: fit-content;
  i {
    margin: 1px 0px;
  }
}
