:local(.container) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;;

  >div {
    width: 350px;
    height: 198px;
  }
}