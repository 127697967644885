@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.viewContainer) {
  display: flex;
  flex-grow: 1;
  padding-right: 6px 1.07692308em;
  align-items: baseline;
  > div:first-child {
    flex-grow: 1;
  }
}

:local(.searchContainer) {
  display: flex;
  flex-grow: 1;
  border-right: 1px solid $colorArctic;
}

:local(.close) {
  right: 0 !important;
  left: auto !important;
}
