@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.loading) {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

:global {
  :local(.container) {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    table {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      td,
      th {
        flex-shrink: 0;
        vertical-align: center;
      }

      tr {
        display: flex;
      }

      tbody {
        flex: 1 1 auto;
        width: 100%;
      }
    }

    #user-group-table {
      td,
      th {
        display: flex;
        align-items: center;
      }
    }
  }
}

:local(.emptyState) {
  padding-bottom: 20px;
}

:local(.centerContent) {
  display: flex;
  justify-content: center;
  align-items: center;
}

:local(.centerContent) i {
  line-height: normal;
}

:local(.loaderContainer) {
  margin: auto;
  margin-top: 25%;
}