:local(.container){
  display: flex;
  align-items: center;
  input {
    min-width: 200px;
  }
  i {
    margin-left: 5px;
  }
}
:local(.contentContainer) {
  composes: contentContainer from '../../../../Folder/Breadcrumb/styles.module.scss'
}

:local(.desktopContainer) {
  min-width: 200px;
}