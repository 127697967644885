@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.divider) {
  border: 0px solid transparent !important;
  margin-bottom: 0 !important;
}

:local(.defaultHeader) {
  display: flex;
  gap: 8px;
  align-items: center;

  p {
    padding: 3px 5px;
    border: 1px solid $colorArctic;
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px;
  }
}

:local(.darkHeader) {
  color: $colorRiverBed;
}

:local(.lightHeader) {
  color: white;
}

.shinyButton {
  background: $premiumGradient !important;
}
