:local(.timeContainer) {
  >div:first-child {
    margin-bottom: 10px;
  }
  >div:last-child {
    flex-grow: 1;
    >div:last-child {
      margin-left: 2px;
    }
  }
}

:local(.minutesBeforeEditingContainer) {
  display: flex;
  >span:last-child {
    flex-shrink: 0;
  }
}