:local(.container) {
  display: flex;
  align-items: center;
  > div {
    margin-bottom: 0px !important;
  }
}

:local(.chevron) {
  margin: 0 0 3px 0 !important;
}
