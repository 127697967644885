:local(.body) {
  td {
    overflow: visible !important;
  }

  :local(.header) {
    font-size: 11px;
  }

  :local(.sortable) {
    vertical-align: top;
    padding-left: 20px !important;
  }
}

:local(.borderless) {
  border: none !important;
  tr,
  td,
  tbody {
    border: none !important;
  }
}

/* don't break header columns */
:local(.horizontalTable) {
  td:first-child {
    word-break: normal;
  }
}
