@import 'src/labstep-web/scss/libs/_vars.scss';

:global {
  :local(.colon) {
    font-weight: bold;
    font-size: 40px;
    color: $colorRiverBed;
    &.red {
      color: $colorBlushing !important;
    }
    margin-top: 30px;
  }
}

:local(.timerContainer) {
  display: flex;
  justify-content: center;
}

:global {
  :local(.timeContainer) {
    display: flex;
    justify-content: space-between;
    &.red {
      color: $colorBlushing !important;
    }
  }
}

:local(.time) {
  min-width: 85px;
  text-align: center;
  transition: color 0.5s ease;
  h1 {
    font-family: poppins;
    font-weight: bold;
    font-size: 60px;
    margin-bottom: 0;
  }

  div {
    color: $colorRiverBed;
    font-family: poppins;
    font-weight: bold;
    font-size: 14px;
  }
}
