@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:global {
  :local(.dateTimeContainer) {
    b {
      color: $colorEarlGray;
    }
    display: flex;
    input.flatpickr-input {
      padding: 0;
      border: 0;
      visibility: hidden;
      opacity: 0;
      width: 0;
      max-width: 0;
    }
  }
}

:global {
  :local(.dateTimePickerOnly) {
    input.flatpickr-input {
      height: 0;
    }
    > div {
      display: none;
    }
  }
}

:local(.toggleContainer) {
  display: flex;
  composes: toggleContainer from '../styles.module.scss';
}
