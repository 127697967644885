@import 'src/labstep-web/scss/libs/_vars.scss';

.imageContainer {
    flex-grow: 1 ;
    flex-shrink: 2;
    max-width: 680px;
    margin-top: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px
}

.shinyButton {
    background: $premiumGradient !important;
}