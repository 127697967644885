/**
 * Labstep
 */

@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  min-width: 400px;
  max-width: 400px !important;
  position: fixed;
  /* So that it appears on top of steps */
  z-index: 1000;
  box-shadow: 1px 3px 3px $colorArctic;
}
