:local(.container) {
  position: relative;
  font-size: small;
}

:local(.fullscreenAction) {
  z-index: 1;
  position: absolute;
  top: 5px;
  left: 5px;
}