:local(.container){
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

:local(.tableContainer) {
  height: calc(100% - 30px);
  margin: -1.5rem -1rem 0 -1.5rem;
};

:local(.footer){
  padding-top:5px;
}