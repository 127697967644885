@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.primary) {
  text-transform: none !important;
  color: $colorEarlGray !important;
  border-top-color: $colorArctic !important;
}

:local(.verticalDividerContainer) {
  display: flex;
  align-items: center;
  margin: 0px 2px;
}

:local(.verticalDivider) {
  height: 1em;
  border-left: 1px solid $colorSilverSurfer;
}

:local(.white) {
  border-left: 1px solid white;
}

:local(.stretch) {
  height: 100%;
  border-left: 1px solid $colorArctic;
}

:local(.horizontalDivider) {
  height: 1px;
  padding: 0px;
  > div {
    border-bottom: 1px solid $colorArctic;
  }
  margin-bottom: 5px;
}
