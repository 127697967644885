:local(.value) {
  margin-left: 5px;
}

:local(.label) {
  font-weight: 500;
}

:local(.fluid) {
  flex-grow: 1;
}

:local(.container) {
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  align-items: baseline;
  padding-top: 3px;

  > *:first-child {
    flex-shrink: 0;
  }
}
