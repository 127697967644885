@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.emptyProfile) {
    height: 30;
    width: 30;
    font-size: 20px;
}

:local(.assignButton) {
    color: $colorPrimary;
    font-weight: bold;
}

:local(.name) {
    font-weight: bold;
}
:local(.description) {
    color: $colorEarlGray;
}

:local(.cardAssigned) {
    border-left-color: $colorBlueGreen;
    border-left-width: 6px;
}

:local(.cardUnassigned) {
    border-left-color: $colorEarlGray;
    border-left-width: 6px;
}