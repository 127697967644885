:local(.container) {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}

:local(.children) {
  height: 100%;
}
