@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.modal) {
  position: fixed;
}

:local(.content) {
  min-height: 200px;
}

:global {
  :local(.inverted) {
    .header {
      color: $colorBlushing !important;
    }
  }
}

:local(.fullscreen) {
  overflow-y: scroll;
}

:local(.actions) {
  margin-top: 10px;
}

:local(.scrolling) {
  max-height: calc(80vh - 10em);
  min-height: calc(80vh - 10em);
  overflow: auto;
}

:local(.redHeading) {
  color: $colorRed;
}