@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  >div {
    padding: 10px 5px;
    cursor: pointer;
  }
}

:local(.active) {
  color: $colorPrimary;
}