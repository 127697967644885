@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.container) {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  overflow: hidden;
}

:local(.contentContainer) {
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  max-width: 100%;

  > div:first-child {
    overflow: auto;
    padding-right: 20px;
    flex-grow: 1;
  }
  > div:last-child {
    overflow: auto;
    @include mobile {
      display: none;
    }
    padding-left: 20px;
    width: 400px;
    flex-shrink: 0;
    flex-grow: 1;
  }
}
