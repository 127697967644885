@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.sideMenuContent) {
  padding: 15px;
};

:local(.newVersion) {
  cursor: pointer;
  margin-left: 10px;
  border-bottom: 1px dashed $colorEarlGray;
};