@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  // set to 0 so the help container doesn't have padding
  padding: 0 !important;
  max-height: 100% !important;
}

:local(.content) {
  flex-grow: 1;
  padding: 20px;
  gap: $gap-3;
  width: 100%;
  height: 100%;
}

:local(.description) {
  font-size: 16px;
  line-height: 1.5;
  max-width: 700px;
}
