:local(.container) {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

:local(.formContainer) {
  width: 100%;
  max-width: 450px;
}
