@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.date) {
  color: $colorEarlGray;
}

:local(.actions) {
  float: right;
}

:local(.value) {
  font-size: 18px;
  font-weight: 500;
}
