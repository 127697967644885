:local(.mobileMenu) {
  position: sticky;
  bottom: 0px;
  padding: 10px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  z-index: 10;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

