@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.defaultContainer) {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
  overflow: hidden;
}

:local(.actionContainer) {
  justify-content: center;
  text-align: center;
}

:local(.title) {
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 5px;
}

:local(.clickable) {
  cursor: pointer;
}

:local(.secondaryHeader) {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  color: $colorRiverBed;
}