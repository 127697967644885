@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.inline) {
  border: 1px solid $colorArctic;
  border-radius: 3px;
  padding: 1px 3px 1px;
  margin: 0px 1px;

  &:hover {
    cursor: pointer;
  }
}

:local(.loaded) {
  display: flex;
  width: fit-content;
  font-weight: bold;
  white-space: initial;
  color: $colorRiverBed;
}

:local(.loading) {
  display: flex;
  width: 50px;
  color: transparent;

  >div:last-child {
    width: 40px; 
    height: 20px;
    >div.line {
      width: 20px!important;
      height: 50%!important;
      margin-top: 12%!important;
    } 
  } 
}

:local(.inverted) {
  background-color: $colorBlushing;
  color: white;
}


:local(.iconContainer){
  display: flex;
  align-items: center;
}