@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  margin: 6px 0px;
  flex-grow: 1;
}

:local(.shiny) {
  background: $premiumGradient !important;
  height: 225px !important;
  border-radius: 6px;
}

:local(.card) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
}

:local(.basic) {
  height: 225px !important;
  border-radius: 6px;
}

:local(.subheading) {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-bottom: 2px solid $colorArctic;
  width: max-content;

  span {
    font-size: 16px;
    font-weight: 600 !important;
  }
}

:local(.logo) {
  background-color: $colorRiverBed;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;

  i {
    font-size: 16px;
    line-height: 28px;
    margin: 0;
  }
}

:local(.upgrade) {
  text-align: end;
  margin-top: 12px;
}

:local(.green) {
  color: $colorCutGrass;
}

:local(.red) {
  color: $colorPunchDrunk;
}

:local(.orange) {
  color: $colorOrange;
}

:local(.emphasized) {
  color: $colorYellow;
  padding-top: 4px;
  font-size: 14px;
  font-weight: 500;
}

:local(.actionContainer) {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

:local(.darkHeader) {
  color: $colorRiverBed;
}

:local(.lightHeader) {
  color: white;
}

:local(.subText) {
  margin-left: 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  vertical-align: middle;
  color: $colorRiverBed;
}
