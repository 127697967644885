:local(.reactionScheme) {
  cursor: pointer;
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
  }
}

:local(.collapsibleCard) {
  overflow: auto;
  table {
    min-width: 1250px;
  }
}
