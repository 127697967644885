@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  height: 100%;
  >div:first-child {
    flex-grow: 1;
    width: 200px;
    border-right: 1px solid $colorArctic;
  }
  >div:last-child {
    flex-grow: 1;
  }
}

:local(.bottomContainer) {
  width: 100%;
  background-color: white;
  padding: 20px;
}