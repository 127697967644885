:local(.listItem) {
  margin-top: 10px;
}

:local(.container) {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

:local(.content) {
  flex-grow: 1;
}