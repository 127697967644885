@import 'src/labstep-web/scss/libs/_vars.scss';

:global {
  :local(.dateTimeContainer) {
    b {
      color: $colorEarlGray;
    }
    display: flex;
    input.flatpickr-input {
      visibility: hidden;
      opacity: 0;
      width: 0;
    }
  }
}

:local(.toggleContainer) {
  display: flex;
  composes: toggleContainer from '../styles.module.scss';
}
