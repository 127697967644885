:local(.container) {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

:local(.threadContainer) {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-bottom: 50px;
  max-width: 1000px;
}