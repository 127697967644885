@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.primary) {
  font-size: 14px;
}

:local(.entityLink) {
  font-weight: 500;
  color: $colorDefaultText
}

:local(.enabled) {
  cursor: pointer;
  color: $colorHyperlink;
}
