@import 'src/labstep-web/scss/libs/_vars.scss';

:global {
  :local(.container) {
    display: flex;
    align-items: center;
    &.toggled {
      flex-grow: 1;
    }
  }
}

:local(.innerContainer) {
  flex-grow: 1;
}

:local(.headerContainer) {
  composes: container;
  color: $colorEarlGray;
}

:local(.header) {
  margin-bottom: 10px;
}

:local(.children) {
  flex-grow: 1;
}

:local(.icon) {
  padding-left: 10px;
  width: 50px;
}

:local(.iconHeader) {
  position: relative;
  composes: icon;
  i {
    position: absolute;
    margin-top: -5px;
  }
  align-self: top;
}

:local(.cursor) {
  &:hover {
    cursor: pointer;
    background-color: $colorArctic;
  }
}

:local(.paddedContainer) {
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 3px;
}

:local(.toggleContainer) {
  composes: cursor;
  composes: paddedContainer;
  div {
    overflow-wrap:anywhere;
  }
}

:local(.label) {
  margin-right: 5px;
  color: $colorEarlGray;
}
