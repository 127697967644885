/**
 * Basic styles
 */

// Makes fonts non blurry/pixelated on most devices
body {
  -webkit-font-smoothing: antialiased;

  #root {
    height: 100%;
  }
}

// Temporary fix since placeholder was rendering white
// Investigate semantic for error.
::placeholder {
  font-size: 14px !important;
  color: #c4c4c4 !important;
  opacity: 1; /* Firefox */
}

html,
body,
#app,
#page-wrapper {
  height: 100%;
}

input,
textarea {
  @include mobile {
    font-size: 16px !important;
  }
}

textarea {
  max-height: 200px;
  overflow: auto;
}

.ui.image.rounded {
  border-radius: 20% !important;
}
