:global {
  :local(.previewContainer) {
    height: 27px;
    overflow: hidden;
    * {
      font-size: small !important;
      font-weight: 500 !important;
      padding: 0px;
      line-height: 27px;
      border: none !important;
    }

    .icon {
      visibility: hidden !important;
      width: 0px;
      margin-left: -5px;
    }
  }
}