:local(.container) {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: white;
  padding: 15px;
  box-shadow: 0px 0px 10px #e7ebf0;
  border-radius: 5px;
  overflow: auto;
}

:local(.breadcrumbContainer) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 47px;
}
